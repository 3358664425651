<template lang="pug">
v-card
    v-card-title {{ $t('PaymentMethod-vue.payment-method') }}
    v-card-text
        .d-flex
            div
                h4 {{ $t('PaymentMethod-vue.card-brand-name') }}
                h3.d-flex
                    v-icon mdi-credit-card-outline
                    .ml-1 {{ subscription.paymentMethod.card.brand.toUpperCase() }}
            .ml-auto
                h4 {{ $t('PaymentMethod-vue.credit-card-ended') }}
                h3 {{ subscription.paymentMethod.card.last4 }}
            .ml-auto
                h4 {{ $t('PaymentMethod-vue.credit-card-expires') }}
                h3 {{ subscription.paymentMethod.card.exp_month < 10 ? '0' + subscription.paymentMethod.card.exp_month : subscription.paymentMethod.card.exp_month }} / {{ subscription.paymentMethod.card.exp_year }}
        .mt-3
            h4 {{ $t('PaymentMethod-vue.card-holder-name') }}
            h3 {{ subscription.paymentMethod.billing_details.name }}
    v-card-actions
        v-dialog(v-model='updateCardOpen', max-width='30%')
            template(v-slot:activator='{ on, attrs }')
                v-btn(text, v-bind='attrs', v-on='on') {{ $t('PaymentMethod-vue.update') }}
            v-card
                v-card-title {{ $t('PaymentMethod-vue.update') }}
                v-card-text
                    v-dialog(
                        ref='datepicker',
                        v-model='datePickeOpen',
                        :return-value.sync='date',
                        transition='scale-transition',
                        persistent,
                        width='290px'
                    )
                        template(v-slot:activator='{ on, attrs }')
                            v-text-field(
                                v-model='date',
                                :label='$t("PaymentMethod-vue.exp-date")',
                                prepend-icon='mdi-calendar',
                                readonly,
                                v-bind='attrs',
                                v-on='on'
                            )
                        v-date-picker(
                            v-model='date',
                            type='month',
                            no-title,
                            scrollable,
                            :min='dayjs().format("YYYY-MM")'
                        )
                            v-spacer
                            v-btn(text, @click='datePickeOpen = false') {{ $t('PaymentMethod-vue.cancel') }}
                            v-btn(text, @click='$refs.datepicker.save(date)') {{ $t('PaymentMethod-vue.ok') }}
                v-card-actions
                    v-btn(text, @click='updateCardOpen = false') {{ $t('PaymentMethod-vue.cancel') }}
                    v-btn(text, @click='updateCardDate') {{ $t('PaymentMethod-vue.ok') }}

        v-dialog(v-model='changeCardOpen', max-width='30%')
            template(v-slot:activator='{ on, attrs }')
                v-btn(text, v-bind='attrs', v-on='on') {{ $t('PaymentMethod-vue.change') }}
            v-card
                v-card-title {{ $t('PaymentMethod-vue.change') }}
                v-card-text
                    stripe-element-card(
                        ref='stripeCard',
                        :pk='pulishableKey',
                        :hidePostalCode='true',
                        @element-change='change'
                    )
                    v-text-field(
                        :label='$t("PaymentMethod-vue.card-holder-name")',
                        v-model='v$.name.$model',
                        :error-messages='v$.name.$errors.map((e) => e.$message)'
                    )
                v-card-actions
                    v-btn(text, @click='changeCardOpen = false') {{ $t('PaymentMethod-vue.cancel') }}
                    v-btn(text, @click='changeCard') {{ $t('PaymentMethod-vue.ok') }}

    v-overlay(:value='loader', :z-index='999')
        v-progress-circular(indeterminate, size='64')
    v-dialog(v-model='changeCardError', persistent, max-width='290')
        v-card
            v-card-title {{ $t('PaymentMethod-vue.change-card-error') }}
            v-card-text {{ changeCardErrorMessage }}
</template>

<script lang="ts">
import { components } from '@/api/models/Epinium';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { ClientsBackend } from '@/api/clients/clients';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

@Component<PaymentMethod>({
    name: 'PaymentMethod',
    setup() {
        return { v$: useVuelidate({}) };
    },
    components: {
        StripeElementCard,
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage(
                    this.$t('PaymentMethod-vue.name-required').toString(),
                    required,
                ),
            },
            filledCard: {
                hasToBeTrue: (v: boolean) => v,
            },
        };
    },
})
export default class PaymentMethod extends Vue {
    public dayjs = dayjs;
    @Prop()
    public subscription!: components['schemas']['SubscriptionResponseDTO'];
    @Ref('stripeCard') card!: any;

    public updateCardOpen = false;
    public changeCardOpen = false;
    public exp_month = 0;
    public exp_year = 0;
    public datePickeOpen = false;
    public loader = false;
    public pulishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    public name = '';

    public created() {
        this.exp_month = this.subscription?.paymentMethod?.card?.exp_month || 0;
        this.exp_year = this.subscription?.paymentMethod?.card?.exp_year || 0;
    }

    public get date() {
        return new Date(this.exp_year, this.exp_month)
            .toISOString()
            .substr(0, 7);
    }
    public set date(d) {
        const date = d.split('-');
        this.exp_year = Number(date[0]);
        this.exp_month = Number(date[1]);
    }

    public async updateCardDate() {
        this.loader = true;
        await new ClientsBackend()
            .editCard({
                exp_month: this.exp_month,
                exp_year: this.exp_year,
            })
            .then((res) => {
                this.subscription.paymentMethod = res;
                this.loader = false;
                this.updateCardOpen = false;
            });
    }

    public changeCardError = false;
    public changeCardErrorMessage = '';
    public filledCard = false;
    public async changeCard() {
        this.v$.$touch();
        if (this.v$.$invalid) {
            return;
        }
        this.loader = true;
        const secret = await new ClientsBackend().changeCard();
        const card = this.card.element;
        const stripe = this.card.stripe;
        if (secret) {
            await stripe
                .confirmCardSetup(secret, {
                    payment_method: {
                        card: card,
                        billing_details: {
                            name: this.name,
                        },
                    },
                })
                .then((result: any) => {
                    if (result.error) {
                        this.changeCardError = true;
                        this.changeCardErrorMessage = result.error.message;
                    } else if (result.setupIntent.status === 'succeeded') {
                        this.loader = false;
                    }
                });
        }
    }

    public change(event: any) {
        this.filledCard = event.complete;
    }
}
</script>

<style lang="scss" scoped></style>
