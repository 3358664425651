import colors from 'vuetify/es5/util/colors';
export const light = {
    primary: colors.deepPurple.base,
    secondary: colors.teal.base,
    accent: colors.pink.base,
    error: colors.red.base,
    warning: colors.orange.base,
    info: colors.blue.base,
    success: colors.green.base,
};
export const dark = {
    primary: colors.deepPurple.base,
    secondary: colors.teal.base,
    accent: colors.blue.base,
    error: colors.red.base,
    warning: colors.deepOrange.base,
    info: colors.lightBlue.base,
    success: colors.green.base,
};
