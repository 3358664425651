<template lang="pug">
v-container.pa-0(fluid)
    AgGrid.ma-auto(
        width='100%',
        :height='`${tableHeight}px`',
        :provider='tableProvider',
        :columnDefs='columnDefs',
        ref='userTable'
    )
        template(slot='right')
            .d-flex
                v-menu(offset-y)
                    template(#activator='{ on, attrs }')
                        v-btn.ml-auto(
                            color='success',
                            v-bind='attrs',
                            v-on='on'
                        ) 
                            v-icon(left) mdi-account-plus
                            | {{ $t('Users-vue.new-user') }}
                    v-list
                        v-list-item(link)
                            CreateUser(@reload='reload')
                        v-list-item(link)
                            CreateSellerUser(@reload='reload')
</template>

<script lang="ts">
import { Component, Vue, Ref } from 'vue-property-decorator';
import { UsersBackend } from '@/api/users/users';
import {
    ColDef,
    ColGroupDef,
    ICellRendererParams,
    NewValueParams,
} from '@ag-grid-community/all-modules';
import AgGrid from '@/components/agGrid.vue';
import AssignedSellersEditor from '@/components/agGridEditors/users/AssignedSellersEditor.vue';
import AssignedCountriesEditor from '@/components/agGridEditors/users/AssignedCountriesEditor.vue';
import AssignedPermissionsEditor from '@/components/agGridEditors/users/AssignedPermissionsEditor.vue';
import PermissionsCellRender from '@/components/agGridRenders/users/PermissionsCellRender.vue';
import RemoveUser from '@/components/users/RemoveUser.vue';
import ChangePassword from '@/components/users/ChangePassword.vue';
import EmailEditor from '@/components/agGridEditors/EmailEditor.vue';
import CreateUser from '@/components/users/CreateUser.vue';
import CreateSellerUser from '@/components/users/CreateSellerUser.vue';

@Component<Users>({
    name: 'Users',
    components: {
        AgGrid,
        CreateUser,
        CreateSellerUser,
    },
})
export default class Users extends Vue {
    public tableProvider = UsersBackend;
    @Ref('userTable') public userTable!: AgGrid;

    public get columnDefs(): Array<ColDef | ColGroupDef> {
        return [
            {
                headerName: this.$tc('Users-vue.name'),
                headerTooltip: this.$tc('Users-vue.name'),
                field: 'name',
                sortable: true,
                type: 'text',
                editable: true,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateInfo(p),
            },
            {
                headerName: this.$tc('Users-vue.last-name'),
                headerTooltip: this.$tc('Users-vue.last-name'),
                field: 'lastName',
                sortable: true,
                type: 'text',
                editable: true,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateInfo(p),
            },
            {
                headerName: this.$tc('Users-vue.email'),
                headerTooltip: this.$tc('Users-vue.email'),
                field: 'email',
                sortable: true,
                type: 'text',
                editable: true,
                cellEditorFramework: EmailEditor,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateEmail(p),
            },
            {
                headerName: this.$tc('Users-vue.sellers'),
                headerTooltip: this.$tc('Users-vue.sellers'),
                field: 'sellers',
                cellRenderer: (p: ICellRendererParams) =>
                    p.value ? p.value.map((a: any) => a.name).join(', ') : '-',
                cellEditorFramework: AssignedSellersEditor,
                editable: true,
                type: 'text',
                filter: false,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateAssignedSellers(p),
            },
            {
                headerName: this.$tc('Users-vue.countries'),
                headerTooltip: this.$tc('Users-vue.countries'),
                field: 'countries',
                cellRenderer: (p: ICellRendererParams) =>
                    p.value
                        ? p.value
                              .map((a: any) =>
                                  this.$tc('countries.names.' + a.name),
                              )
                              .join(', ')
                        : '-',
                cellEditorFramework: AssignedCountriesEditor,
                editable: true,
                type: 'text',
                filter: false,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateCountries(p),
            },
            {
                headerName: this.$tc('Users-vue.permissions.title'),
                headerTooltip: this.$tc('Users-vue.permissions.title'),
                field: 'permissions',
                cellRendererFramework: PermissionsCellRender,
                cellEditorFramework: AssignedPermissionsEditor,
                editable: true,
                type: 'text',
                filter: false,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updatePermissions(p),
            },
            {
                headerName: this.$tc('Users-vue.phone'),
                headerTooltip: this.$tc('Users-vue.phone'),
                field: 'phone',
                sortable: true,
                type: 'text',
                editable: true,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateInfo(p),
            },
            {
                headerName: this.$tc('Users-vue.actions.change-password'),
                headerTooltip: this.$tc('Users-vue.actions.change-password'),
                field: '_id',
                maxWidth: 140,
                cellRendererFramework: ChangePassword,
            },
            {
                headerName: this.$tc('Users-vue.actions.remove'),
                headerTooltip: this.$tc('Users-vue.actions.remove'),
                field: '_id',
                maxWidth: 140,
                cellRendererParams: {
                    delete: this.deleteUser,
                },
                cellRendererFramework: RemoveUser,
            },
        ];
    }
    public reload() {
        this.userTable.reloadTable();
    }
    public async deleteUser(_id: string) {
        await new this.tableProvider().removeUser(_id);
        this.reload();
    }
    public tableHeight = 400;
    changeTableHeight() {
        this.tableHeight = window.innerHeight - 70 ?? 400;
    }
    public async mounted() {
        this.changeTableHeight();
        window.onresize = this.changeTableHeight;
    }
}
</script>

<style lang="scss" scoped></style>
