<template lang="pug">
v-chip(
    small,
    :color='params.value && params.value.color ? params.value.color : undefined'
) {{ params.value && params.value.text ? params.value.text : params.value }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<ChipCellRender>({ name: 'ChipCellRender' })
export default class ChipCellRender extends Vue implements ICellRendererComp {
    public getGui!: any;
    public params!: ICellRendererParams;
    public refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
}
</script>
