import { render, staticRenderFns } from "./AddSellerProcess.vue?vue&type=template&id=34efca89&scoped=true&lang=pug"
import script from "./AddSellerProcess.vue?vue&type=script&lang=ts"
export * from "./AddSellerProcess.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.37_css-loader@6.11.0_webpack@5.93.0__ejs@3.1.10_loda_nnqsrpekrpzikkhlqd4krx7lae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34efca89",
  null
  
)

export default component.exports