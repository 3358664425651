import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Users from '@/views/Users.vue';
import Sellers from '@/views/Sellers.vue';
import ClientSettings from '@/views/ClientSettings.vue';
import UserSettings from '@/views/UserSettings.vue';
import Billing from '@/views/Billing.vue';
import Login from '@/views/Login.vue';
import i18n from '@/i18n';
import UserStore from '@/store/user.vuex';
import ClientStore from '@/store/client.vuex';
import MaintenancePage from '@/views/MaintenancePage.vue';
Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { icon: 'mdi-home' },
    },
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ '../views/About.vue'),
    // },
    {
        path: '/users',
        name: 'users',
        meta: {
            icon: 'mdi-account-group',
            group: 'adminSettings',
            permissions: ['admin'],
        },
        component: Users,
    },
    {
        path: '/connections',
        name: 'connections',
        meta: {
            icon: 'mdi-connection',
            group: 'adminSettings',
            permissions: ['admin'],
        },
        component: Sellers,
    },
    {
        path: '/clientSettings',
        name: 'client-settings',
        meta: {
            icon: 'mdi-account-cog',
            group: 'adminSettings',
            permissions: ['admin'],
        },
        component: ClientSettings,
    },
    {
        path: '/userSettings',
        name: 'user-settings',
        meta: { icon: 'mdi-account-cog' },
        component: UserSettings,
    },
    {
        path: '/billing',
        name: 'billing',
        meta: {
            icon: 'mdi-cash-multiple',
            group: 'adminSettings',
            permissions: ['admin'],
        },
        component: Billing,
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            login: true,
            noMenu: true,
        },
        component: Login,
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: MaintenancePage,
        meta: { maintenance: true, noMenu: true },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// Comprobación de permisos mediante cookie. En el backend se realizará la comprobación mediante el payload

router.beforeEach(async (to, from, next) => {
    if (Vue.cookie.get('access')) {
        await ClientStore.getClient();
        await UserStore.setUserInfo();
    }

    if (to.name) {
        const lng = Vue.cookie.get('lang') || i18n.locale || navigator.language;
        document.title = `${i18n
            .t(`sections.${to.name}`)
            .toString()} | Enterprise Edition | Epinium [${
            lng.toLocaleUpperCase().split('-')[0]
        }]`;
    }
    if (to.meta && to.meta.permissions) {
        const permissions = UserStore.userData?.userPermissions || [];
        if (
            !to.meta.permissions.some((rp: string) =>
                permissions.find((p: string) => rp === p),
            )
        ) {
            Vue.cookie.delete('access', { domain: 'epinium.com' });
            Vue.cookie.delete('access', { domain: 'localhost' });
            Vue.cookie.delete('access');
            next('login');
        }
    }
    next();
});

export default router;
