<template lang="pug">
v-container.py-0(fluid)
    v-switch(v-model='value')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorComp,
    ICellEditorParams,
} from '@ag-grid-community/all-modules';

@Component<BooleanEditor>({ name: 'BooleanEditor' })
export default class BooleanEditor extends Vue implements ICellEditorComp {
    public params!: ICellEditorParams;
    public getGui!: any;
    public value = false;
    public refresh(params: ICellEditorParams) {
        this.params = params;
        return true;
    }
    public getValue() {
        return this.value;
    }
    public isPopup() {
        return true;
    }
    public created() {
        this.value = this.params.value;
    }
}
</script>

<style lang="scss" scoped></style>
