import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import en from '@/locales/en';
// import es from '@/locales/es';
import en from '@/locales/en.json5';
import es from '@/locales/es.json5';
import it from '@/locales/it.json5';
import fr from '@/locales/fr.json5';
import de from '@/locales/de.json5';

import vuetifyEN from 'vuetify/src/locale/en';
import vuetifyES from 'vuetify/src/locale/es';
import vuetifyIT from 'vuetify/src/locale/it';
import vuetifyFR from 'vuetify/src/locale/fr';
import vuetifyDE from 'vuetify/src/locale/de';

Vue.use(VueI18n);

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {
        en: {
            ...en,
            $vuetify: vuetifyEN,
        },
        es: {
            ...es,
            $vuetify: vuetifyES,
        },
        it: {
            ...it,
            $vuetify: vuetifyIT,
        },
        fr: {
            ...fr,
            $vuetify: vuetifyFR,
        },
        de: {
            ...de,
            $vuetify: vuetifyDE,
        },
    },
    fallbackRoot: true,
    silentFallbackWarn: true,
});
