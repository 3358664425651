import {
    VuexModule,
    Module,
    Mutation,
    Action,
    config,
    getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { components } from '@/api/models/Epinium';
import { UsersBackend } from '@/api/users/users';
config.rawError = true;

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'user',
})
class UserStore extends VuexModule {
    public userData: components['schemas']['LoginResponseDTO'] | null = null;

    @Mutation
    setUser(user: components['schemas']['LoginResponseDTO']) {
        this.userData = user;
    }

    @Action({ commit: 'setUser' })
    async setUserInfo(force = false) {
        const clientData =
            force || !this.userData
                ? await new UsersBackend().getUserBasicInfo()
                : this.userData;
        this.setIntercomUser(clientData);
        return clientData;
    }

    @Action({})
    setIntercomUser(user: components['schemas']['LoginResponseDTO']) {
        window.Intercom('boot', {
            name: user.name,
            email: user.email,
            phone: user.phone,
            language_override: user.lang,
            created_at: user.createdAt
                ? +new Date(user.createdAt) / 1000
                : undefined,
            user_id: user.id,
            company: {
                name: user.client.name,
                company_id: user.client._id,
                created_at: user.client.createdAt
                    ? +new Date(user.client.createdAt) / 1000
                    : undefined,
                plan: user.client.subscription?.plan.tier,
            },
            stripe_id: user.client.subscription?.stripeCustomerId,
            user_hash: user.intercomUserHash,
        });
    }

    @Action({})
    tagManagerEvent(event: string, data?: any) {
        if (!window.dataLayer) return;
        window.dataLayer.push({
            event,
            ...data,
        });
    }
}

export default getModule(UserStore);
