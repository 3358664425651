import { API } from '../API';
import { paths } from '../models/Epinium';

class MaintenanceBackend extends API {
    async checkMaintenanceMode(): Promise<{
        isInMaintenanceMode: boolean;
        messageEs: string;
        messageEn: string;
    }> {
        const res: paths['/maintenance-mode/check-maintenance-mode']['get']['responses']['200']['content']['application/json'] =
            await this.get('/maintenance-mode/check-maintenance-mode')
                .then((res) => {
                    return res.data;
                })
                .catch(() => {
                    // if (err?.status === HttpStatusCode.ServiceUnavailable) {
                    // }
                    return { maintenanceMode: true };
                });

        return {
            isInMaintenanceMode: res?.maintenanceMode,
            messageEn: res?.messageEn,
            messageEs: res?.messageEs,
        };
    }
}
export const MaintenanceAPI = new MaintenanceBackend();
