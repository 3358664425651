<template lang="pug">
v-container.fill-height(fluid)
    v-container(fluid)
        v-row(align='center', justify='center')
            v-col(cols='12', sm='8', md='4')
                div(align='center')
                    .logo
                    .text-h3.font-italic.font-weight-bold.mb-5.mt-5(
                        align='center'
                    ) {{ $t('maintenance.title') }}
                    p.text-subtitle-1.font-weight-medium {{ maintenanceMessage }}
</template>

<script lang="ts">
import { MaintenanceAPI } from '@/api/maintenance/maintenance.api';
import { Component, Vue } from 'vue-property-decorator';
@Component({
    name: 'MaintenancePage',
})
export default class MaintenancePage extends Vue {
    public maintenanceMessage = '';
    public async beforeCreate() {
        // Check if the maintenance mode is enabled
        await MaintenanceAPI.checkMaintenanceMode().then((response) => {
            if (this.$i18n.locale === 'es') {
                this.maintenanceMessage =
                    response?.messageEs ??
                    this.$t('maintenance.subtitle').toString();
            } else {
                this.maintenanceMessage =
                    response?.messageEn ??
                    this.$t('maintenance.subtitle').toString();
            }
            // If the maintenance mode is NOT enabled, redirect to the main page
            if (response.isInMaintenanceMode !== true) {
                this.$router.push({ name: 'home' });
            }
        });
    }
}
</script>
<style lang="scss" scoped>
.logo {
    margin: 0 auto;
    height: 80px;
    background-color: #673ab7;
    mask: url('~@/assets/img/logo-epinium.svg') no-repeat center;
    -webkit-mask: url('~@/assets/img/logo-epinium.svg') no-repeat center;
}
</style>
