<template lang="pug">
v-container.fill-height(fluid)
    v-container(fluid)
        v-row(align='center', justify='center')
            v-col(cols='12', sm='8', md='4')
                v-card
                    v-card-text.pa-8
                        .logo
                        .text-h7.grey--text(align='center') {{ $t('Login-vue.agency-edition') }}
                        v-text-field.mt-10(
                            :label='$t("Login-vue.email")',
                            type='email',
                            v-model='email',
                            :error='hasError',
                            v-on:keyup.enter='login'
                        )
                        v-text-field(
                            :label='$t("Login-vue.password")',
                            type='password',
                            v-model='password',
                            :error='hasError',
                            v-on:keyup.enter='login'
                        )
                        v-row
                            v-col(cols='12')
                                v-btn.primary.float-end(@click='login') {{ $t('Login-vue.log-in') }}
                            v-col.mt-10(align='center')
                                | {{ $t('Login-vue.are-you-seller') }}
                                a.ml-2(href='https://app.epinium.com') {{ $t('Login-vue.seller-edition') }}
    v-footer(absolute)
        v-row(justify='center', no-gutters)
            a.mx-2(href='https://epinium.com/') {{ $t('Login-vue.home') }}
            | |
            a.mx-2(href='https://epinium.com/pricing/') {{ $t('Login-vue.pricing') }}
            | |
            a.mx-2(href='https://epinium.com/terms-of-service/') {{ $t('Login-vue.terms') }}
            | |
            a.mx-2(href='https://epinium.com/cookies-policy/') {{ $t('Login-vue.privacy') }}
            | |
            label.mx-2 © {{ new Date().getFullYear() }} Epinium. {{ $t('Login-vue.reserved') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { LoginBackend } from '@/api/login/login';
import FingerprintJS, { UnknownComponents } from '@fingerprintjs/fingerprintjs';
@Component<Login>({ name: 'Login' })
export default class Login extends Vue {
    public email = '';
    public password = '';
    public hasError = false;
    async login() {
        const loginBackend = new LoginBackend();
        const loginSuccess = await loginBackend
            .login({
                email: this.email,
                password: this.password,
                fingerPrint: this.fingerPrint,
            })
            .catch(() => {
                this.hasError = true;
            });

        // Si nos retorna un 200, podemos ir al home
        if (loginSuccess && typeof loginSuccess.id !== 'undefined') {
            this.$cookie.set('access', loginSuccess.accessToken, {
                expires: '7d',
                domain:
                    process.env.NODE_ENV === 'development' ? '' : 'epinium.com',
            });
            this.$cookie.set(
                'permissions',
                loginSuccess.userPermissions.join(),
                {
                    expires: '7d',
                    domain:
                        process.env.NODE_ENV === 'development'
                            ? ''
                            : 'epinium.com',
                },
            );
            this.$router.push({ name: 'home' });
        }
    }

    public fingerPrint = '';
    public async created() {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const customComponents: UnknownComponents = {
            ...result.components,
        };
        delete customComponents.availableScreenResolution;
        delete customComponents.screenResolution;
        this.fingerPrint = FingerprintJS.hashComponents(customComponents);
    }
}
</script>

<style lang="scss" scoped>
.logo {
    margin: 0 auto;
    height: 100px;

    background-color: #673ab7;
    mask: url(~@/assets/img/logo-epinium.svg) no-repeat center;
    -webkit-mask: url(~@/assets/img/logo-epinium.svg) no-repeat center;
}
</style>
