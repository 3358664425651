<template lang="pug">
v-container(fluid)
    v-row(justify='center')
        v-dialog(
            v-model='dialog',
            persistent,
            max-width='600px',
            transition='dialog-bottom-transition'
        )
            template(v-slot:activator='{ on }')
                v-btn(color='primary', v-on='on', icon)
                    v-icon mdi-circle-edit-outline
            PasswordChanger(
                :userID='this.params.value',
                :admin='true',
                @closeDialog='closeDialog'
            )
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import PasswordChanger from '@/components/passwordChanger/PasswordChanger.vue';
import delay from 'lodash/delay';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required, sameAs } from '@vuelidate/validators';

@Component<ChangePassword>({
    name: 'ChangePassword',
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            password: {
                maxLength: maxLength(30),
                minLength: minLength(8),
                required,
            },
            passwordConfirmation: {
                required,
                sameAsPassword: sameAs(this.password),
            },
        };
    },
    components: {
        PasswordChanger,
    },
})
export default class ChangePassword extends Vue implements ICellRendererComp {
    public getGui!: any;
    public dialog = false;
    public params!: ICellRendererParams;
    public password = '';
    public passwordConfirmation = '';

    public refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }

    public closeDialog(reloadTable = false) {
        // quitamos el dialog
        if (reloadTable) {
            delay(() => {
                this.reloadTable();
            }, 500);
        } else {
            this.dialog = false;
        }
    }

    public reloadTable() {
        if (this.params && this.params.api) {
            this.params.api.purgeInfiniteCache();
            this.params.api.setRowCount(200, false);
            this.params.api.ensureIndexVisible(1);
            this.params.api.getSelectedNodes().forEach((r) => {
                r.setSelected(false);
            });
        }
    }
}
</script>

<style lang="scss" scoped></style>
