<template lang="pug">
v-dialog(
    v-model='dialog',
    persistent,
    max-width='600px',
    transition='dialog-bottom-transition'
)
    template(v-slot:activator='{ on }')
        span(v-on='on') {{ $tc('CreateSellerUser-vue.create-user.title') }}
    v-card
        v-card-title
            span.headline {{ $tc('CreateSellerUser-vue.create-user.title') }}
        v-card-text
            v-container
                v-row
                    v-col(cols='12')
                        v-text-field(
                            v-model='v$.email.$model',
                            :label='$t("CreateSellerUser-vue.create-user.email")',
                            required,
                            :error-messages='v$.email.$errors.map((e) => e.$message)'
                        )
                        //- v-if='clientSellers.length',
                        v-select(
                            v-model='v$.sellersSelected.$model',
                            :items='clientSellers',
                            item-text='name',
                            item-value='_id',
                            multiple,
                            :label='$t("CreateSellerUser-vue.create-user.sellers.title")',
                            :error-messages='v$.sellersSelected.$errors.map((e) => e.$message)'
                        )
                        small {{ $tc('CreateSellerUser-vue.create-user.required') }}
                        br
                        small {{ $tc('CreateSellerUser-vue.create-user.one-user-per-account') }}
                        v-alert(
                            v-if='alreadyExists',
                            border='right',
                            colored-border,
                            type='error',
                            elevation='2'
                        ) {{ $t('CreateSellerUser-vue.errors.save.already-exists') }}
                        v-alert(
                            v-if='unknownError',
                            border='right',
                            colored-border,
                            type='error',
                            elevation='2'
                        ) {{ $t('CreateSellerUser-vue.errors.save.unknown') }}

        v-card-actions
            v-spacer
            v-btn(color='blue darken-1', text='', @click='close') {{ $tc('CreateSellerUser-vue.close') }}
            v-btn(color='blue darken-1', text='', @click='submitCreateUser') {{ $tc('CreateSellerUser-vue.save') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { UsersBackend } from '@/api/users/users';
import { SellersBackend } from '@/api/sellers/sellers';
import { VueTelInput } from 'vue-tel-input';
import { components, paths } from '@/api/models/Epinium';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';

@Component<CreateSellerUser>({
    name: 'CreateSellerUser',
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage(
                    this.$t(
                        'CreateSellerUser-vue.errors.email.required',
                    ).toString(),
                    required,
                ),
                email: helpers.withMessage(
                    this.$t(
                        'CreateSellerUser-vue.errors.email.no-valid',
                    ).toString(),
                    email,
                ),
            },
            sellersSelected: {
                required: helpers.withMessage(
                    this.$t('CreateSellerUser-vue.errors.sellers').toString(),
                    required,
                ),
            },
        };
    },
    components: { VueTelInput },
    watch: {
        async dialog(v) {
            if (!v) {
                this.clientSellers = [];
            } else {
                const sellersBackend = new SellersBackend();
                this.clientSellers = await sellersBackend
                    .getClientSellers({ getSellersForClient: true })
                    .catch((e) => {
                        throw e;
                    });
            }
        },
    },
})
export default class CreateSellerUser extends Vue {
    public dialog = false;
    //form
    public email = '';
    public sellersSelected: string[] = [];
    public clientSellers: components['schemas']['ClientSellersResponseDTO'][] =
        [];
    public alreadyExists = false;
    public unknownError = false;

    public async created() {
        // cogemos los sellers
    }

    public async submitCreateUser() {
        // ponemos los errores del request a false
        this.unknownError = false;
        this.alreadyExists = false;
        // hacemos que se toque todo el formulario para ver si hay algo invalido
        this.v$.$touch();
        if (!this.v$.$invalid) {
            const newUser: paths['/users-crm/create-seller-user']['post']['requestBody']['content']['application/json'] =
                {
                    email: this.email,
                    sellers: this.sellersSelected,
                };
            const usersBackend = new UsersBackend();
            await usersBackend
                .createSellerUser(newUser)
                .then(() => {
                    this.close();
                })
                .catch((err) => {
                    if (err.alreadyExists) {
                        this.alreadyExists = true;
                    } else {
                        this.unknownError = true;
                    }
                });
        }
    }

    public close() {
        this.email = '';
        this.sellersSelected = [];

        // descomprobamos todo
        this.v$.$reset();

        // quitamos el dialog
        this.dialog = false;

        // hacemos un emit para recargar la tabla
        this.$emit('reload');
    }
}
</script>

<style lang="scss" scoped></style>
