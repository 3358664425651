<template lang="pug">
.d-flex(v-if='vendorToUploadProducts && vendorToUploadProducts.isVendor')
    UploadVendorProductsExcelModal(
        :vendor='vendorToUploadProducts',
        :modalIsOpen.sync='uploadVendorProductsModalIsOpen'
    )
    v-tooltip(top)
        template(v-slot:activator='{ on, attrs }')
            v-btn.ma-auto(
                color='primary',
                dark,
                @click='uploadVendorProductsModalIsOpen = true',
                icon,
                v-on='on'
            )
                v-icon mdi-file-excel
        span {{ $t('UploadVendorExcelButton-vue.upload-vendor-products') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import UploadVendorProductsExcelModal from '@/components/excels/UploadVendorProductsExcelModal.vue';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import { components } from '@/api/models/Epinium';

@Component<UploadVendorExcelButton>({
    name: 'UploadVendorExcelButton',
    components: {
        UploadVendorProductsExcelModal,
    },
})
export default class UploadVendorExcelButton
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public dialog = false;
    public params!: ICellRendererParams & {
        refresh: (_id: string) => Promise<void>;
    };
    public loading = false;
    public refreshing = false;
    public vendorToUploadProducts:
        | components['schemas']['SellerTableRespDTO']
        | any = {};

    public uploadVendorProductsModalIsOpen = false;

    public created() {
        this.vendorToUploadProducts =
            typeof this.params !== undefined &&
            this.params.data &&
            this.params.data !== 'undefined'
                ? this.params.data
                : undefined;
        // this.refreshing =
        // typeof this.params !== undefined &&
        // this.params.data &&
        // this.params.data.allReportsDone !== 'undefined'
        //     ? !this.params.data.allReportsDone
        //     : true;
    }

    public refresh(
        params: ICellRendererParams & {
            refresh: (_id: string) => Promise<void>;
        },
    ) {
        this.params = params;
        return true;
    }
}
</script>

<style lang="scss" scoped></style>
