<template lang="pug">
v-card
    v-list(v-if='params && params.value', dense, max-height='300')
        v-radio-group.ml-4(v-model='permisionRol')
            v-radio(
                v-for='(permission, i) in allPermissions',
                :key='i',
                :disabled='disabled || permission.disabled',
                :value='permission.name',
                :label='$t("AssignedPermissionsEditor-vue.permissions." + permission.name)'
            )
    v-card-text(v-else) {{ $t('AssignedPermissionsEditor-vue.no-permissions') }}
    v-card-actions
        v-btn(color='error', @click='cancel') {{ $tc('AssignedPermissionsEditor-vue.cancel') }}
        v-btn(color='success', @click='accept') {{ $tc('AssignedPermissionsEditor-vue.accept') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorParams,
    ICellEditorComp,
} from '@ag-grid-community/all-modules';
import { UsersBackend } from '@/api/users/users';
import cloneDeep from 'lodash/cloneDeep';

type Permission = { name: string; assigned?: boolean; disabled?: boolean };

@Component<AssignedPermissionsRender>({
    name: 'AssignedPermissionsRender',
})
export default class AssignedPermissionsRender
    extends Vue
    implements ICellEditorComp
{
    public params!: ICellEditorParams;
    public getGui!: any;
    public permissions: Permission[] = [];
    public originalPermisions: Permission[] = [];
    public allPermissions = [
        { name: 'admin' },
        { name: 'worker' },
        { name: 'client', disabled: true },
    ];
    public disabled = false;
    public permisionRol: 'admin' | 'worker' | 'client' | '' = '';

    public async created() {
        this.permissions = this.params.value.map((p: string) => ({
            assigned: true,
            name: p,
        }));
        new UsersBackend()
            .checkIfAnyOtherAdmin(this.params.data._id, this.params.data.client)
            .then((is) => {
                if (is) {
                    const found = this.permissions.find(
                        (p) => p.name === 'admin',
                    );
                    found && (this.disabled = true);
                }
            });
        this.originalPermisions = cloneDeep(this.permissions);
        if (this.originalPermisions.some((p) => p.name === 'agency-client')) {
            this.disabled = true;
        }
        this.permisionRol = this.originalPermisions.some(
            (p) => p.name === 'admin',
        )
            ? 'admin'
            : this.originalPermisions.some((p) => p.name === 'agency-client')
              ? 'client'
              : 'worker';
    }

    public refresh(params: ICellEditorParams) {
        this.params = params;
        return true;
    }
    public getValue() {
        return this.originalPermisions
            .filter((c: Permission) => c.assigned)
            .map((p) => p.name);
    }
    public isPopup() {
        return true;
    }

    public accept() {
        switch (this.permisionRol) {
            case 'admin':
                this.permissions = [{ name: 'admin', assigned: true }];
                break;
            case 'worker':
                this.permissions = [];
                break;
            case 'client':
                this.permissions = [{ name: 'agency-client', assigned: true }];
        }
        this.originalPermisions = this.permissions;
        this.params.stopEditing();
    }
    public cancel() {
        this.params.stopEditing();
    }
}
</script>

<style lang="scss" scoped>
.v-list {
    overflow-y: auto;
}
</style>
