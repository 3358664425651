<template lang="pug">
v-container#settings.pa-6(fluid)
    v-row
        v-col(cols='12', md='12')
            v-card
                v-list-item
                    v-list-item-content
                        v-skeleton-loader(
                            height='430',
                            :loading='!clientInfo',
                            type='table'
                        )
                            v-list-item-title.headline.mb-1 {{ $t('ClientSettings-vue.form.client.header') }}
                            v-form(ref='clientform', v-if='clientInfo')
                                v-row
                                    v-col(cols='12', md='4')
                                        v-text-field(
                                            v-model='v$.name.$model',
                                            :label='$t("ClientSettings-vue.form.client.name.placeholder")',
                                            required,
                                            :error-messages='v$.name.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.email.$model',
                                            :label='$t("ClientSettings-vue.form.client.email.placeholder")',
                                            required,
                                            :error-messages='v$.email.$errors.map((e) => e.$message)'
                                        )
                                        v-select(
                                            v-model='vat_type',
                                            :items='vat_types',
                                            item-text='name',
                                            item-value='value',
                                            :label='$t("ClientSettings-vue.form.client.vat_type.placeholder")',
                                            :error-messages='v$.vat_type.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.VAT.$model',
                                            label='VAT',
                                            :label='$t("ClientSettings-vue.form.client.vat.placeholder")',
                                            :error-messages='v$.VAT.$errors.map((e) => e.$message)'
                                        )
                                    v-col(cols='12', md='4')
                                        v-autocomplete(
                                            v-model='v$.country.$model',
                                            :label='$t("ClientSettings-vue.form.client.country.placeholder")',
                                            :items='countries',
                                            item-text='name',
                                            item-value='code',
                                            :error-messages='v$.country.$errors.map((e) => e.$message)'
                                        )
                                            template(v-slot:selection='data')
                                                CountryFlag.selected-flag(
                                                    :country='data.item.code',
                                                    size='small'
                                                )
                                                span.ml-4 {{ data.item.name }}
                                            template(v-slot:item='data')
                                                CountryFlag(
                                                    :country='data.item.code'
                                                )
                                                span.ml-4 {{ data.item.name }}
                                        v-text-field(
                                            v-model='v$.province.$model',
                                            :label='$t("ClientSettings-vue.form.client.province.placeholder")',
                                            editable=false,
                                            :error-messages='v$.province.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.city.$model',
                                            :label='$t("ClientSettings-vue.form.client.city.placeholder")',
                                            :error-messages='v$.city.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.businessName.$model',
                                            label='Business Name',
                                            :label='$t("ClientSettings-vue.form.client.business_name.placeholder")',
                                            :error-messages='v$.businessName.$errors.map((e) => e.$message)'
                                        )
                                    v-col(cols='12', md='4')
                                        v-text-field(
                                            v-model='v$.postalCode.$model',
                                            :label='$t("ClientSettings-vue.form.client.postal_code.placeholder")',
                                            :error-messages='v$.postalCode.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.contactName.$model',
                                            :label='$t("ClientSettings-vue.form.client.contact_name.placeholder")',
                                            :error-messages='v$.contactName.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.phone.$model',
                                            :label='$t("ClientSettings-vue.form.client.phone.placeholder")',
                                            :error-messages='v$.phone.$errors.map((e) => e.$message)'
                                        )
                                        v-text-field(
                                            v-model='v$.address.$model',
                                            label='Address',
                                            :label='$t("ClientSettings-vue.form.client.address.placeholder")',
                                            :error-messages='v$.address.$errors.map((e) => e.$message)'
                                        )
                                v-btn.float-right(
                                    color='success',
                                    @click='submitClientForm'
                                ) {{ $t('ClientSettings-vue.form.send') }}
                            v-snackbar(
                                v-model='snackbar',
                                :color='!changeClientInfoError ? "success" : "error"'
                            )
                                | {{ snackbarMessage }}
                                v-btn(text, @click='snackbar = false') {{ $t('ClientSettings-vue.snackbar.close') }}
        v-col(cols='4')
            v-card
                v-card-text
                    v-form(ref='customLogo')
                        v-hover(v-slot:default='{ hover }')
                            v-card(
                                :elevation='hover ? 12 : 2',
                                height='80',
                                width='260',
                                color='primary',
                                @click='$refs.logo.$refs.input.click()'
                            )
                                v-img.primary(
                                    :src='logoPreview',
                                    src-lazy='https://media.epinium.com/images/web/logo-epinium.svg',
                                    height='80',
                                    width='260'
                                )
                        i(
                            v-if='clientLogos && clientLogos.big && clientLogos.big.key'
                        ) {{ clientLogos.big.key.split('/')[1] }}
                        v-file-input(
                            ref='logo',
                            :label='$t("ClientSettings-vue.form.client.logo.placeholder")',
                            v-model='v$.logo.$model',
                            show-size,
                            truncate-length='55',
                            accept='image/png, .svg',
                            persistent-hint,
                            error-count='3',
                            :messages='[$t("ClientSettings-vue.form.client.logo.hint1"), $t("ClientSettings-vue.form.client.logo.hint2"), $t("ClientSettings-vue.form.client.logo.hint3")]',
                            :error-messages='v$.logo.$errors.map((e) => e.$message)'
                        )
                v-card-actions
                    v-btn.ml-auto(
                        color='success',
                        @click='submitLogoForm("big")'
                    ) {{ $t('ClientSettings-vue.form.send-logo') }}
        v-col(cols='4')
            v-card
                v-card-text
                    v-form(ref='customLogo')
                        v-hover(v-slot:default='{ hover }')
                            v-card(
                                color='primary',
                                :elevation='hover ? 12 : 2',
                                height='80',
                                width='80',
                                @click='$refs.logoSmall.$refs.input.click()'
                            )
                                v-img.primary(
                                    :src='logoSmallPreview',
                                    src-lazy='https://media.epinium.com/images/web/logo-epinium-small.svg',
                                    height='80',
                                    width='80'
                                )
                        i(
                            v-if='clientLogos && clientLogos.small && clientLogos.small.key'
                        ) {{ clientLogos.small.key.split('/')[1] }}
                        v-file-input(
                            ref='logoSmall',
                            :label='$t("ClientSettings-vue.form.client.logo-small.placeholder")',
                            v-model='v$.logoSmall.$model',
                            show-size,
                            truncate-length='55',
                            accept='image/png, .svg',
                            persistent-hint,
                            error-count='3',
                            :messages='[$t("ClientSettings-vue.form.client.logo-small.hint1"), $t("ClientSettings-vue.form.client.logo-small.hint2"), $t("ClientSettings-vue.form.client.logo-small.hint3")]',
                            :error-messages='v$.logoSmall.$errors.map((e) => e.$message)'
                        )
                v-card-actions
                    v-btn.ml-auto(
                        color='success',
                        @click='submitLogoForm("small")'
                    ) {{ $t('ClientSettings-vue.form.send-logo') }}
</template>

<script lang="ts">
import { Component, Vue, Ref } from 'vue-property-decorator';
import { ClientsBackend } from '../api/clients/clients';
import { ClientInfoDTO } from '../api/clients/dto/client-info.dto';

import CountryFlag from 'vue-country-flag';
import ClientStore from '@/store/client.vuex';
import useVuelidate from '@vuelidate/core';
import {
    helpers,
    maxLength,
    maxValue,
    minLength,
    required,
    requiredIf,
    email,
    alpha,
} from '@vuelidate/validators';
import { ValidateSpanishID } from '@/components/billing/validateCIF-NIF-NIE';

import i18nCountries from 'i18n-iso-countries';
import esCountries from 'i18n-iso-countries/langs/es.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
import itCountries from 'i18n-iso-countries/langs/it.json';
import frCountries from 'i18n-iso-countries/langs/fr.json';
import deCountries from 'i18n-iso-countries/langs/de.json';

i18nCountries.registerLocale(esCountries);
i18nCountries.registerLocale(enCountries);
i18nCountries.registerLocale(itCountries);
i18nCountries.registerLocale(frCountries);
i18nCountries.registerLocale(deCountries);

@Component<ClientSettings>({
    name: 'ClientSettings',
    components: { CountryFlag },
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage(
                    this.$t(
                        'ClientSettings-vue.form.client.name.required',
                    ).toString(),
                    required,
                ),
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(30),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
            },
            email: {
                required: helpers.withMessage(
                    this.$t(
                        'ClientSettings-vue.form.client.email.required',
                    ).toString(),
                    required,
                ),
                email,
            },
            vat_type: {
                minLength: helpers.withMessage(
                    this.$tc(
                        'ClientSettings-vue.form.client.vat_type.required',
                    ).toString(),
                    required,
                ),
            },
            VAT: {
                required: requiredIf('vat_type'),
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(15),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(4),
                ),
                isValid: (vat: any, parent: any) => {
                    if (
                        !parent.vat_type ||
                        ['es_cif', 'eu_vat'].indexOf(parent.vat_type) < 0
                    )
                        return true;
                    switch (parent.vat_type) {
                        case 'es_cif':
                            return ValidateSpanishID(vat).valid;
                        case 'eu_vat':
                            return /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/i.test(
                                vat,
                            );
                        default:
                            return false;
                    }
                },
            },
            businessName: {
                required: helpers.withMessage(
                    this.$t(
                        'ClientSettings-vue.form.client.business_name.required',
                    ).toString(),
                    required,
                ),
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(60),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
            },
            country: {
                alpha: helpers.withMessage(
                    this.$t('ClientSettings-vue.form.alpha').toString(),
                    alpha,
                ),
                required: helpers.withMessage(
                    this.$t(
                        'ClientSettings-vue.form.client.country.required',
                    ).toString(),
                    required,
                ),
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(10),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
            },
            address: {
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(50),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
                required,
            },
            city: {
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(50),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
            },
            contactName: {
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(50),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
            },
            phone: {
                required: requiredIf('phone_type'),
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(15),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(10),
                ),
                isValid: (phone: any) => {
                    return /^\+?[1-9]\d{1,14}$/i.test(phone);
                },
            },
            postalCode: {
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(20),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
                required,
            },
            province: {
                maxLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-long').toString(),
                    maxLength(20),
                ),
                minLength: helpers.withMessage(
                    this.$tc('ClientSettings-vue.form.too-short').toString(),
                    minLength(2),
                ),
            },
            logo: {
                size: {
                    required: requiredIf(() => {
                        return this.logo ? true : false;
                    }),
                    max: helpers.withMessage(
                        this.$tc(
                            'ClientSettings-vue.form.client.logo.max-size-error',
                        ).toString(),
                        maxValue(10000),
                    ),
                },
                name: {
                    required: requiredIf(() => {
                        return this.logo ? true : false;
                    }),
                    max: helpers.withMessage(
                        this.$tc(
                            'ClientSettings-vue.form.client.logo.max-name-error',
                        ).toString(),
                        maxLength(250),
                    ),
                },
            },
            logoSmall: {
                size: {
                    required: requiredIf(() => {
                        return this.logoSmall ? true : false;
                    }),
                    max: helpers.withMessage(
                        this.$tc(
                            'ClientSettings-vue.form.client.logo-small.max-size-error',
                        ).toString(),
                        maxValue(10000),
                    ),
                },
                name: {
                    required: requiredIf(() => {
                        return this.logoSmall ? true : false;
                    }),
                    max: helpers.withMessage(
                        this.$tc(
                            'ClientSettings-vue.form.client.logo-small.max-name-error',
                        ).toString(),
                        maxLength(250),
                    ),
                },
            },
        };
    },
})
export default class ClientSettings extends Vue {
    // Client form
    @Ref() clientform!: Vue & Element & { validate(): boolean };
    public clientInfo: ClientInfoDTO | null = null;
    public clientFormValid = true;
    public name: ClientInfoDTO['name'] = '';
    public email: ClientInfoDTO['email'] = '';
    public VAT: ClientInfoDTO['VAT'] = '';
    public vat_type: ClientInfoDTO['vat_type'] = '';
    public businessName: ClientInfoDTO['businessName'] = '';
    public country: ClientInfoDTO['country'] = '';
    public address: ClientInfoDTO['address'] = '';
    public city: ClientInfoDTO['city'] = '';
    public contactName: ClientInfoDTO['contactName'] = '';
    public phone: ClientInfoDTO['phone'] = '';
    public postalCode: ClientInfoDTO['postalCode'] = '';
    public province: ClientInfoDTO['province'] = '';
    public snackbar = false;
    public changeClientInfoError = false;
    public snackbarMessage = '';
    public logo: File | null = null;
    public logoSmall: File | null = null;
    public clientLogos: ClientInfoDTO['logo'] = { big: {}, small: {} };
    public vat_types = [
        { value: 'ae_trn', name: 'United Arab Emirates TRN' },
        { value: 'au_abn', name: 'Australian Business Number (AU ABN)' },
        { value: 'br_cnpj', name: 'Brazilian CNPJ number' },
        { value: 'br_cpf', name: 'Brazilian CPF number' },
        { value: 'ca_bn', name: 'Canadian BN' },
        { value: 'ca_qst', name: 'Canadian QST number' },
        { value: 'ch_vat', name: 'Switzerland VAT number' },
        { value: 'cl_tin', name: 'Chilean TIN' },
        { value: 'es_cif', name: 'Spanish CIF number' },
        { value: 'eu_vat', name: 'European VAT number' },
        { value: 'hk_br', name: 'Hong Kong BR number' },
        { value: 'id_npwp', name: 'Indonesian NPWP number' },
        { value: 'in_gst', name: 'Indian GST number' },
        { value: 'jp_cn', name: 'Japanese Corporate Number (Hōjin Bangō)' },
        { value: 'kr_brn', name: 'Korean BRN' },
        { value: 'li_uid', name: 'Liechtensteinian UID number' },
        { value: 'mx_rfc', name: 'Mexican RFC Number' },
        { value: 'my_frp', name: 'Malaysian FRP number' },
        { value: 'my_itn', name: 'Malaysian ITN' },
        { value: 'my_sst', name: 'Malaysian SST number' },
        { value: 'no_vat', name: 'Norwegian VAT number' },
        { value: 'nz_gst', name: 'New Zealand GST number' },
        { value: 'ru_inn', name: 'Russian INN' },
        { value: 'sa_vat', name: 'Saudi Arabia VAT' },
        { value: 'sg_gst', name: 'Singaporean GST' },
        { value: 'sg_uen', name: 'Singaporean UEN' },
        { value: 'th_vat', name: 'Thai VAT' },
        { value: 'tw_vat', name: 'Taiwanese VAT' },
        { value: 'us_ein', name: 'United States EIN' },
        { value: 'za_vat', name: 'South Africa VAT Number' },
    ];

    public countriesISO = Object.keys(i18nCountries.getAlpha2Codes());
    public countries: { name: string; code: string }[] = this.countriesISO.map(
        (fl) => ({
            name: i18nCountries.getName(fl, this.$i18n.locale ?? 'en') ?? fl,
            code: fl,
        }),
    );
    // Form Rules
    public get emailRules() {
        return [
            (v: string) =>
                !!v ||
                this.$tc('ClientSettings-vue.form.client.email.required'),
            (v: string) =>
                /.+@.+\..+/.test(v) ||
                this.$tc('ClientSettings-vue.form.client.email.validation'),
        ];
    }

    public async created() {
        this.getClientBasicInfo();
    }

    public async submitClientForm() {
        this.v$.$touch();
        if (!this.v$.$invalid && this.clientInfo) {
            const clientsBackend = new ClientsBackend();
            const updateClientResp = await clientsBackend
                .changeClientInfo({
                    update: {
                        name: this.name,
                        email: this.email,
                        VAT: this.VAT,
                        vat_type: this.vat_type,
                        businessName: this.businessName,
                        country: this.country,
                        address: this.address,
                        city: this.city,
                        contactName: this.contactName,
                        phone: this.phone,
                        postalCode: this.postalCode,
                        province: this.province,
                    },
                })
                .catch(() => {
                    return false;
                });
            if (updateClientResp) {
                this.snackbarMessage = this.$tc('ClientSettings-vue.success');
                this.changeClientInfoError = false;
                if (window.rewardful)
                    window.rewardful('convert', { email: this.email });
            } else {
                this.snackbarMessage = this.$tc('ClientSettings-vue.failed');
                this.changeClientInfoError = true;
            }
            this.snackbar = true;
        }
    }

    async getClientBasicInfo() {
        // const clientsBackend = new ClientsBackend();
        // const clientInfo = await clientsBackend.getClientInfo().catch(() => {
        //     // console.error(err);
        //     return null;
        // });
        await ClientStore.getClient();
        const clientInfo = ClientStore.clientData;

        if (clientInfo) {
            this.clientInfo = clientInfo;
            this.name = clientInfo.name;
            this.email = clientInfo.email;
            this.VAT = clientInfo.VAT;
            this.businessName = clientInfo.businessName;
            this.country = clientInfo.country;
            this.address = clientInfo.address;
            this.city = clientInfo.city;
            this.contactName = clientInfo.contactName;
            this.phone = clientInfo.phone;
            this.postalCode = clientInfo.postalCode;
            this.province = clientInfo.province;
            this.clientLogos = clientInfo.logo;
            this.vat_type = clientInfo.vat_type;
        }
    }

    public get logoPreview() {
        if (this.logo && this.logo?.size && this.logo?.size <= 10000) {
            return URL.createObjectURL(this.logo);
        } else if (this.clientLogos?.big) return this.clientLogos.big.url;
        else return 'https://media.epinium.com/images/web/logo-epinium.svg';
    }

    public get logoSmallPreview() {
        if (this.logoSmall?.size && this.logoSmall?.size <= 10000) {
            return URL.createObjectURL(this.logoSmall);
        } else if (this.clientLogos?.small) return this.clientLogos.small.url;
        else
            return 'https://media.epinium.com/images/web/logo-epinium-small.svg';
    }

    public async submitLogoForm(size: 'small' | 'big') {
        let logo: File;
        switch (size) {
            case 'small':
                this.v$.logoSmall.$touch();
                if (!this.logoSmall || this.v$.logoSmall.$invalid) return;
                logo = this.logoSmall;
                break;
            case 'big':
                this.v$.logo.$touch();
                if (!this.logo || this.v$.logo.$invalid) return;
                logo = this.logo;
                break;
            default:
                return;
        }
        const formData = new FormData();
        formData.append('file', logo);
        formData.append('size', size);
        await new ClientsBackend()
            .uploadLogo(formData as any)
            .then(() => {
                this.snackbarMessage = this.$tc('ClientSettings-vue.success');
                this.changeClientInfoError = false;
            })
            .catch(() => {
                this.snackbarMessage = this.$tc('ClientSettings-vue.failed');
                this.changeClientInfoError = true;
            });
        this.snackbar = true;
    }
    public get VATErrors() {
        const errors: string[] = [];
        if (!this.$v.VAT.$dirty) return errors;
        !this.$v.VAT?.required &&
            errors.push(this.$tc('ClientSettings-vue.errors.VAT.required'));
        !this.$v.VAT?.isValid &&
            errors.push(this.$tc('ClientSettings-vue.errors.VAT.isValid'));
        !this.$v.VAT?.validated &&
            errors.push(this.$tc('ClientSettings-vue.errors.VAT.validated'));
        return errors;
    }
}
</script>

<style lang="scss" scoped></style>
