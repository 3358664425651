import { API } from '../API';
import { IGetRowsParams, GridApi } from '@ag-grid-community/all-modules';
import { components } from '../models/Epinium';
export class SellersHomeInfoBackend extends API {
    public table(params: IGetRowsParams, gridApi: GridApi | null) {
        gridApi?.showLoadingOverlay();
        this.post('/home/sellers-table', params).then((response) => {
            const sellers: components['schemas']['SellerHomeTableRespDTO'][] =
                response.data;
            params.successCallback(
                sellers,
                sellers.length < params.endRow
                    ? params.startRow + sellers.length
                    : -1,
            );
            gridApi?.hideOverlay();
        });
    }
}
