import { API } from '../API';
import {
    IGetRowsParams,
    GridApi,
    NewValueParams,
} from '@ag-grid-community/all-modules';
import partition from 'lodash/partition';
import difference from 'lodash/difference';
import xorBy from 'lodash/xorBy';
import {
    PasswordChangedDTO,
    UserBasicInfoChangeDTO,
} from './dto/user-info.dto';
import { User } from './dto/user.dto';
import { paths } from '../models/Epinium';
import { AxiosError } from 'axios';
export class UsersBackend extends API {
    public async getUserBasicInfo(): Promise<
        paths['/users-crm/basic-user-info']['get']['responses']['200']['content']['application/json']
    > {
        return this.get('/users-crm/basic-user-info')
            .then((res) => {
                if (res.data) {
                    return res.data;
                } else {
                    throw {
                        err: 'no_data',
                        message: 'No data getting basic user info',
                    };
                }
            })
            .catch((err) => {
                throw err;
            });
    }

    // Función para modificar un password a un usuario
    public async changeUserPassword(params: {
        currentPassword?: string; // Esto solo será obligatorio si quien cambia la password no es admin
        newPassword: string;
        userId?: string;
        admin: boolean;
    }): Promise<PasswordChangedDTO> {
        return this.post('/users-crm/change-user-password', params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public table(params: IGetRowsParams, gridApi: GridApi | null) {
        gridApi?.showLoadingOverlay();
        this.post('/users-crm/table', params).then((response) => {
            const users: User[] = response.data;
            params.successCallback(
                users,
                users.length < params.endRow
                    ? params.startRow + users.length
                    : -1,
            );
            gridApi?.hideOverlay();
        });
    }
    public updateAssignedSellers(p: NewValueParams) {
        type seller = {
            _id: string;
            name: string;
            assigned?: boolean;
        };
        const diff = xorBy(
            p.oldValue as seller[],
            p.newValue as seller[],
            '_id',
        );
        if (diff.length) {
            const [toAdd, toRemove] = partition(diff, 'assigned');
            return this.post('/users-crm/change-sellers', {
                user: p.data._id,
                toAdd: toAdd.map((a) => a._id),
                toRemove: toRemove.map((a) => a._id),
            });
        }
        return;
    }
    public updateAssignedUsers(p: NewValueParams) {
        type user = {
            _id: string;
            name: string;
            assigned?: boolean;
        };
        const diff = xorBy(p.oldValue as user[], p.newValue as user[], '_id');
        if (diff.length) {
            const [toAdd, toRemove] = partition(diff, 'assigned');
            return this.post('/users-crm/change-users-to-seller', {
                seller: p.data._id,
                toAdd: toAdd.map((a) => a._id),
                toRemove: toRemove.map((a) => a._id),
            });
        }
        return;
    }

    public async createUser(
        newUser: paths['/users-crm/create-user']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/users-crm/create-user']['post']['responses']['201']['content']['application/json']
    > {
        const res = await this.post('/users-crm/create-user', newUser).catch(
            (err: AxiosError) => {
                return err.response;
            },
        );
        return res?.data;
    }
    public async createSellerUser(
        newUser: paths['/users-crm/create-seller-user']['post']['requestBody']['content']['application/json'],
    ): Promise<void> {
        const res = await this.post('/users-crm/create-seller-user', newUser);
        switch (res.status) {
            case 201:
                return;
            case 409:
                throw { alreadyExists: true };
            case 500:
                throw new Error('An error has occurred');
            default:
                break;
        }
    }

    public updateCountries(p: NewValueParams) {
        type Country = {
            _id: string;
            name: string;
            assigned?: boolean;
        };
        const diff = xorBy(
            p.oldValue as Country[],
            p.newValue as Country[],
            '_id',
        );
        if (diff.length) {
            const [toAdd, toRemove] = partition(diff, 'assigned');
            return this.post('/users-crm/change-countries', {
                user: p.data._id,
                toAdd: toAdd.map((a) => a._id),
                toRemove: toRemove.map((a) => a._id),
            });
        }
        return;
    }

    public updatePermissions(p: NewValueParams) {
        const toAdd = p.newValue;
        const toRemove = difference(p.oldValue, toAdd);
        if (toRemove.length || p.newValue.length) {
            return this.post('/users-crm/change-permissions', {
                user: p.data._id,
                client: p.data.client,
                toAdd: toAdd,
                toRemove: toRemove,
            });
        }
        return;
    }

    public updateInfo(p: NewValueParams) {
        const columnName: string = p.colDef.field as string;
        if (p.oldValue !== p.newValue) {
            return this.post('/users-crm/change-info', {
                user: p.data._id,
                update: {
                    [columnName]: p.newValue,
                },
            });
        }
        return;
    }

    public updateUserSettingsInfo(data: UserBasicInfoChangeDTO) {
        return this.post('/users-crm/change-info', {
            user: data.user,
            update: data.update,
        });
    }

    public updateEmail(p: NewValueParams) {
        if (p.oldValue !== p.newValue) {
            return this.post('/users-crm/change-email', {
                user: p.data._id,
                update: {
                    email: p.newValue,
                },
            });
        }
        return;
    }

    public checkEmail(email: string): Promise<boolean> {
        return this.get('/users-crm/check-email', {
            email,
        })
            .then((r) => r.data.available)
            .catch((e) => {
                throw e;
            });
    }

    public removeUser(user: string) {
        return this.post('/users-crm/remove-user', {
            user,
        });
    }

    public checkIfAnyOtherAdmin(
        userId: string,
        clientId: string,
    ): Promise<boolean> {
        return this.get('/users-crm/check-last-admin', {
            userId,
            clientId,
        })
            .then((res) => res.data.isLastAdmin)
            .catch((err) => {
                // console.error(err);
                throw err;
            });
    }

    public getAllUsers(
        params: paths['/users-crm/get-all-users']['get']['parameters']['query'],
    ): Promise<
        paths['/users-crm/get-all-users']['get']['responses']['200']['content']['application/json']
    > {
        return this.get('/users-crm/get-all-users', params).then(
            (res) => res.data,
        );
    }
}
