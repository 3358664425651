import { API } from '../API';
export enum AmazonRegions {
    europe = 'europe',
    india = 'india',
    america = 'america',
    farEast = 'farEast',
}
export class AmazonBackend extends API {
    public async getAdvertisingLoginUrls(params: {
        sellerID: string;
        region: AmazonRegions;
    }): Promise<string> {
        return this.get('/amazon-crm/build-adv-credentials-url', params).then(
            (res) => res.data,
        );
    }
}
