<template lang="pug">
div {{ getValue }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import currencyFormatter from 'currency-formatter';

@Component<CurrencyCellRender>({ name: 'CurrencyCellRender' })
export default class CurrencyCellRender
    extends Vue
    implements ICellRendererComp
{
    public params!: ICellRendererParams;
    public getGui!: any;
    public refresh(params: ICellRendererParams) {
        this.params = params;
        return false;
    }
    public get getValue() {
        return this.params.value
            ? currencyFormatter.format(this.params.value.value || 0, {
                  code: this.params.value.currency,
              })
            : '-';
    }
}
</script>

<style lang="scss" scoped></style>
