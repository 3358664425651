<template lang="pug">
v-card
    v-card-title {{ $t('Subscription-vue.next-invoice') }}
    v-card-text
        h4 {{ $t('Subscription-vue.next-invoice-date') }}
        .d-flex.align-center
            h3 {{ subscription.incoming.next_payment_attempt ? dayjs.unix(subscription.incoming.next_payment_attempt).format($t('date-format-table')) : '-' }}
            template(v-if='dataCancelSubscription?.isPermanence')
                v-chip.ml-2.white--text(label, color='green')
                    v-icon.mr-2(size='small') mdi mdi-key
                    | {{ $t('Subscription-vue.permanence') }}: {{ dataCancelSubscription.lastDateUsePlatform }}

        h4.mt-3 {{ $t('Subscription-vue.next-invoice') }}
        h3.d-flex.align-center
            | {{ priceFromat(subscription.incoming.subtotal) }}
            v-dialog(v-model='invoiceDetailsOpen', scrollable, max-width='50%')
                template(v-slot:activator='{ on, attrs }')
                    v-btn.ml-2(
                        color='primary',
                        dark,
                        icon,
                        small,
                        v-bind='attrs',
                        v-on='on'
                    )
                        v-icon mdi-information
                v-card
                    v-card-text
                        v-simple-table(
                            v-if='subscription.incoming?.lines?.data && subscription.incomingLines?.length'
                        )
                            template(v-slot:default)
                                thead
                                    tr
                                        th
                                            h2 {{ $t('Subscription-vue.concept') }}
                                        th
                                            h2 {{ $t('Subscription-vue.amount') }}
                                tbody
                                    tr(
                                        v-for='line in subscription.incomingLines'
                                    )
                                        td {{ line.description }}
                                        td {{ priceFromat(line.amount) }}
                                tfoot
                                    tr(v-if='totalFromPrevious')
                                        v-tooltip(bottom, color='info')
                                            template(
                                                v-slot:activator='{ on, attrs }'
                                            )
                                                th(v-bind='attrs', v-on='on') {{ $t('Subscription-vue.total-pending-from-previous-period') }}
                                            span {{ $t('Subscription-vue.pending-explanation') }}
                                        th {{ priceFromat(totalFromPrevious) }}
                                    tr(v-if='totalFromPrevious')
                                        v-tooltip(bottom, color='info')
                                            template(
                                                v-slot:activator='{ on, attrs }'
                                            )
                                                th(v-bind='attrs', v-on='on') {{ $t('Subscription-vue.total-pending-tax-from-previous-period') }}
                                            span {{ $t('Subscription-vue.pending-explanation') }}
                                        th {{ priceFromat(totalTaxFromPrevious) }}
                                    tr
                                        th
                                            h2 {{ $t('Subscription-vue.subtotal') }}
                                        th
                                            h2 {{ priceFromat(subscription.incoming.subtotal) }}
                                    tr
                                        th
                                            h2 {{ $t('Subscription-vue.tax') }}
                                        th
                                            h2 {{ priceFromat(subscription.incoming.tax) }}
                                    tr
                                        th
                                            h2 {{ $t('Subscription-vue.total') }}
                                        th
                                            h2 {{ priceFromat(subscription.incoming.total) }}
    v-card-actions
        v-btn(text, href='mailto:support@epinium.com') {{ $t('Subscription-vue.cancel-subscription') }}
</template>

<script lang="ts">
import { components } from '@/api/models/Epinium';
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import currencyFormatter from 'currency-formatter';

@Component<Subscription>({
    name: 'Subscription',
    watch: {
        subscription() {
            if (this.subscription?.incomingLines.length) {
                this.subscription.incomingLines.forEach((c: any) => {
                    if (c.proration) {
                        this.totalFromPrevious += c.amount;
                        this.totalTaxFromPrevious += c.tax_amounts
                            ? c.tax_amounts[0]['amount']
                            : 0;
                    }
                });
            }
        },
    },
})
export default class Subscription extends Vue {
    public dayjs = dayjs;
    @Prop()
    public subscription!: components['schemas']['SubscriptionResponseDTO'];
    public totalFromPrevious = 0;
    public totalTaxFromPrevious = 0;
    public invoiceDetailsOpen = false;
    @Prop() public dataCancelSubscription: {
        lastDateUsePlatform: string;
        isPermanence: boolean;
    } | null = null;
    public priceFromat(n: number) {
        return currencyFormatter.format(n / 100, {
            code: 'EUR',
            locale: this.$i18n.locale,
        });
    }
}
</script>

<style lang="scss" scoped></style>
