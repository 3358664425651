<template lang="pug">
v-dialog(v-model='isOpen', scrollable, max-width='600px')
    template(v-slot:activator='{ on, attrs }')
        v-btn(v-on='on', text, tile, x-small) {{ status }}
    v-card(v-if='params && params.value')
        v-simple-table
            template(v-slot:default)
                thead
                    tr
                        th.text-left {{ $tc('PortfolioCellRenders-vue.name') }}
                        th.text-left {{ $tc('PortfolioCellRenders-vue.serving-status') }}
                tbody
                    tr(v-for='(p, i) in params.value', :key='i')
                        td {{ p.name }}
                        td {{ $tc(p.servingStatus) }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<PortfolioCellRender>({ name: 'PortfolioCellRender' })
export default class PortfolioCellRender
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public params!: ICellRendererParams;
    public isOpen = false;
    public status = '-';
    public created() {
        if (this.params && this.params.value) {
            this.status = this.params.value.some(
                (p: { servingStatus: string }) =>
                    p.servingStatus.indexOf('OUT_OF_BUDGET') > -1,
            )
                ? this.$tc('PortfolioCellRenders-vue.out_of_budget')
                : this.$tc('PortfolioCellRenders-vue.all_correct');
        }
    }
    public refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
}
</script>

<style lang="scss" scoped></style>
