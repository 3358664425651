<template lang="pug">
v-app
    v-navigation-drawer(
        v-if='$route.meta && !$route.meta.login && !$route.meta.maintenance',
        color='primary',
        app,
        dark,
        overflow
    )
        v-list
            v-list-item
                v-img(src='/img/logo-epinium.svg')
        v-divider
        v-list(dense, nav)
            v-list-item(
                v-for='(route, index) in notGroupedRoutes',
                :key='index',
                exact,
                :to='{ name: route.name }'
            )
                v-list-item-icon(v-if='route.meta && route.meta.icon')
                    v-icon {{ route.meta.icon }}
                v-list-item-title {{ $t('sections.' + route.name) }}
            template(v-if='adminRoutes.length')
                v-list-group(color='white', prepend-icon='mdi-key')
                    template(v-slot:activator)
                        v-list-item-content
                            v-list-item-title {{ $tc('App-vue.admin') }}
                    v-list-item(
                        v-for='(route, index) in adminRoutes',
                        :key='index',
                        exact,
                        :to='{ name: route.name }',
                        link
                    )
                        v-list-item-title(inset) {{ $t('sections.' + route.name) }}
                        v-list-item-icon(v-if='route.meta && route.meta.icon')
                            v-icon {{ route.meta.icon }}
            v-divider
            v-list(dense, nav)
                v-btn(block, @click='openEpiniumAPP', color='secondary')
                    v-icon(left) mdi-open-in-new
                    | {{ $t('App-vue.go-epinium') }}
            v-divider
            v-list(dense, nav)
                v-list-item(link, @click='logout')
                    v-list-item-title {{ $t('App-vue.logout') }}

    v-main
        v-container(v-if='client && client.trial', fluid)
            v-alert.mb-0(dense, type='info')
                i18n(path='remaining-trial-days', tag='span')
                    template(#days) {{ dayjs().diff(client.trialEnd, 'days') }}
                    template(#link)
                        a.white--text(href='mailto:support@epinium.com') {{ $t('App-vue.get-plan') }}
        router-view
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { routes } from './router';
import ClientStore from '@/store/client.vuex';
import dayjs from 'dayjs';
import { UsersBackend } from './api/users/users';
@Component<App>({
    name: 'App',
})
export default class App extends Vue {
    public routes = routes;
    public client = ClientStore.clientData;
    public dayjs = dayjs;
    public async created() {
        this.lang();
    }
    public logout() {
        this.$cookie.delete('access', { domain: 'epinium.com' });
        this.$cookie.delete('access', { domain: 'localhost' });
        this.$cookie.delete('permissions', { domain: 'localhost' });
        this.$cookie.delete('permissions', { domain: 'localhost' });
        this.$router.push({ name: 'login' });
    }

    public openEpiniumAPP() {
        window.open('https://app.epinium.com/', '_blank');
    }

    // Función para leer la cookie del lenguaje y setearlo
    public async lang() {
        const usersBackend = new UsersBackend();
        const userLang = await usersBackend
            .getUserBasicInfo()
            .then((p) => p.lang)
            .catch(() => {
                return 'en';
            });
        const locale = this.$cookie.get('locale');
        if (locale && locale === userLang) {
            this.$vuetify.lang.current = locale;
            this.$root.$i18n.locale = locale;
        } else {
            this.$vuetify.lang.current = userLang;
            this.$root.$i18n.locale = userLang;
            this.$cookie.set('locale', userLang, { expires: '30D' });
        }
    }

    public get notGroupedRoutes() {
        return this.routes.filter(
            (r) => !r.meta || (!r.meta.noMenu && !r.meta.group),
        );
    }
    public get adminRoutes() {
        return this.routes.filter(
            (r) => r.meta && r.meta.group && r.meta.group === 'adminSettings',
        );
    }
    public get isAccountSettingsActive() {
        return true;
    }
}
</script>

<style lang="scss">
html {
    overflow: auto !important;
}
.v-application--wrap {
    background-color: #e8e8e8;
}
</style>
