<template lang="pug">
v-container(fluid)
    upload-vendor-products-excel-modal(
        v-if='vendorToUploadProducts',
        :modalIsOpen.sync='uploadVendorProductsModalIsOpen',
        :vendor='vendorToUploadProducts'
    )
    v-dialog(
        v-model='dialog',
        max-width='1000px',
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on }')
            .d-flex
                slot(name='left')
                v-btn(color='success', v-on='on')
                    v-icon(left) mdi-connection
                    | {{ $tc('AddSellerProcess-vue.titles.title') }}
        v-stepper(v-model='step')
            v-stepper-header
                v-stepper-step(:complete='step > 1', step='1') {{ $tc('AddSellerProcess-vue.titles.step-1') }}
                v-divider
                v-stepper-step(:complete='step > 2', step='2') {{ $tc('AddSellerProcess-vue.titles.step-2') }}
                v-divider
                v-stepper-step(:complete='step > 3', step='3') {{ $tc('AddSellerProcess-vue.titles.step-3') }}
                v-divider
                v-stepper-step(:complete='step > 4', step='4') {{ $tc('AddSellerProcess-vue.titles.step-4') }}
            v-stepper-items
                v-stepper-content(step='1')
                    v-card
                        v-card-text.d-flex
                            v-btn.mx-5(
                                color='success',
                                @click='(integrationTypeSelected = "seller"), step++',
                                large
                            ) Seller
                            v-btn.white--text(
                                color='blue',
                                @click='(integrationTypeSelected = "vendor"), step++',
                                large
                            ) Vendor
                        v-card-actions
                            v-spacer
                            v-btn(
                                color='blue darken-1',
                                text,
                                @click='close()'
                            ) {{ $tc('AddSellerProcess-vue.btns.close') }}
                v-stepper-content(step='2')
                    v-card(v-if='integrationTypeSelected === "seller"')
                        // SELLER MODE
                        v-card-text
                            v-select(
                                v-model='regionSelected',
                                :items='regions',
                                item-text='name',
                                item-value='value'
                            )
                        v-card-actions
                            v-spacer
                            v-btn(
                                v-if='!createSellerLoading && !integrationSucceed',
                                color='blue darken-1',
                                text,
                                @click='step--'
                            ) {{ $tc('AddSellerProcess-vue.btns.back') }}
                            v-btn(color='blue darken-1', text, @click='step++') {{ $tc('AddSellerProcess-vue.btns.next') }}
                    v-card(v-else)
                        // VENDOR MODE
                        v-card-text
                            // SELECTOR PAIS AQUI
                            v-row
                                v-col(cols='12')
                                    v-card(elevation=4)
                                        v-card-text {{ $tc('AddSellerProcess-vue.step-2.vendor-countries-advice') }}
                            v-row
                                v-col
                                    v-chip.ma-1(
                                        label,
                                        v-for='country in countries',
                                        v-bind:key='country._id',
                                        large,
                                        color='primary',
                                        outlined,
                                        @click='(countrySelected = country), step++'
                                    )
                                        .d-flex.align-center
                                            .mr-2
                                                CountryFlag(
                                                    :country='country.code',
                                                    size='normal'
                                                )
                                            | {{ country.name }}
                        v-card-actions
                            v-spacer
                            v-btn(
                                v-if='!createSellerLoading && !integrationSucceed',
                                color='blue darken-1',
                                text,
                                @click='step--'
                            ) {{ $tc('AddSellerProcess-vue.btns.back') }}
                v-stepper-content(step='3')
                    // SELLER MODE
                    v-card(v-if='integrationTypeSelected === "seller"')
                        v-card-text
                            v-container
                                v-row
                                    h2.ma.headline {{ $tc('AddSellerProcess-vue.step-3.region.title') }}
                                v-row
                                    v-chip.ma-1(
                                        label,
                                        v-for='country in regionCountries()',
                                        v-bind:key='country',
                                        large,
                                        color='primary',
                                        outlined
                                    )
                                        .d-flex.align-center
                                            .mr-2
                                                CountryFlag(
                                                    :country='country',
                                                    size='normal'
                                                )
                                            | {{ $t(`countries.codes.${country}`) }}
                                v-row
                                    v-col(cols='12')
                                        v-card(elevation=4)
                                            v-card-text {{ $tc('AddSellerProcess-vue.step-3.explanation-seller') }}
                                v-row
                                    v-col(cols='12', sm='6')
                                        v-text-field(
                                            v-model='v$.name.$model',
                                            :label='$t("AddSellerProcess-vue.step-3.seller-form.name.title")',
                                            :placeholder='$t("AddSellerProcess-vue.step-3.seller-form.name.placeholder")',
                                            required,
                                            :error-messages='v$.name.$invalid ? $t("AddSellerProcess-vue.errors.name") : null'
                                        )
                        v-card-actions
                            v-spacer
                            v-btn(color='blue darken-1', text, @click='step--') {{ $tc('AddSellerProcess-vue.btns.back') }}
                            v-btn(
                                color='blue darken-1',
                                text,
                                :disabled='v$.$invalid',
                                :loading='createSellerLoading',
                                @click='amazonSpPopup("seller")'
                            ) {{ $tc('AddSellerProcess-vue.btns.connect') }}

                    // VENDOR MODE
                    v-card(v-else)
                        v-card-text
                            v-container
                                v-row
                                    v-col(cols='12')
                                        v-card(elevation=4)
                                            v-card-text {{ $tc('AddSellerProcess-vue.step-3.explanation-vendor') }}
                                v-row
                                    v-col(cols='12', sm='6')
                                        v-text-field(
                                            v-model='v$.name.$model',
                                            :label='$t("AddSellerProcess-vue.step-3.seller-form.name.title")',
                                            :placeholder='$t("AddSellerProcess-vue.step-3.seller-form.name.placeholder")',
                                            required,
                                            :error-messages='v$.name.$errors.map((e) => e.$message)'
                                        )
                                v-row
                                    v-col(cols='12')
                                        v-alert(dense, outlined, type='error') {{ $tc('AddSellerProcess-vue.step-3.vendor-selection-warning') }}
                        v-card-actions
                            v-spacer
                            v-btn(color='blue darken-1', text, @click='step--') {{ $tc('AddSellerProcess-vue.btns.back') }}
                            v-btn(
                                color='blue darken-1',
                                text,
                                :disabled='v$.$invalid',
                                :loading='createSellerLoading',
                                @click='amazonSpPopup("vendor")'
                            ) {{ $tc('AddSellerProcess-vue.btns.connect') }}
                v-stepper-content(step='4')
                    v-card
                        v-card-text
                            v-timeline
                                v-timeline-item(color='white', right)
                                    template(v-slot:icon)
                                        v-icon(
                                            v-if='integrationAlreadyExists || !validateIntegration'
                                        ) mdi-alert-circle
                                        v-progress-circular(
                                            v-else-if='!integrationSucceed',
                                            indeterminate,
                                            color='primary'
                                        )
                                        v-icon(v-else, color='primary') mdi-check-bold
                                    span.title(v-if='integrationAlreadyExists') {{ $tc('AddSellerProcess-vue.errors.seller-id.exists') }}
                                    span.title(
                                        v-else-if='!validateIntegration'
                                    ) {{ $tc('AddSellerProcess-vue.errors.invalid-credentials') }}
                                    span.title(v-else-if='integrationSucceed') {{ $tc('AddSellerProcess-vue.step-4.time-line.seller-integration.success') }}
                                    span.title(v-else) {{ $tc('AddSellerProcess-vue.step-4.time-line.seller-integration.integrating') }}
                                // CREDENCIALES ADV
                                v-timeline-item(
                                    v-if='integrationSucceed && integrationTypeSelected === "seller"',
                                    color='white',
                                    right
                                )
                                    template(v-slot:icon)
                                        v-icon(
                                            v-if='hasCredentialsToCheck',
                                            color='primary'
                                        ) mdi-magnify
                                        v-icon(v-else, color='primary') mdi-alert-circle
                                    span.title(v-if='hasCredentialsToCheck') {{ $tc('AddSellerProcess-vue.step-4.time-line.adv-integration.check-adv-credentials') }}
                                    span.title(v-else) {{ $tc('AddSellerProcess-vue.step-4.time-line.adv-integration.need-to-integrate') }}
                                v-timeline-item(
                                    v-if='integrationSucceed',
                                    color='white',
                                    right
                                )
                                    template(v-slot:icon)
                                        v-icon(color='primary') mdi-check-bold
                                    span.title {{ $tc('AddSellerProcess-vue.step-4.finish-integration') }}
                                // AÑADIR EXCEL VENDOR
                                v-timeline-item(
                                    v-if='integrationSucceed && integrationTypeSelected === "vendor"',
                                    color='white',
                                    right
                                )
                                    template(v-slot:icon)
                                        v-icon(color='primary') mdi-file-excel
                                    p.title {{ $tc('AddSellerProcess-vue.step-4.add-vendor-excel') }}
                                    v-btn(
                                        @click='uploadVendorProductsModalIsOpen = true'
                                    ) {{ $tc('AddSellerProcess-vue.step-4.add-vendor-excel-button') }}
                        v-card-actions
                            v-spacer
                            v-btn(
                                v-if='!createSellerLoading && !integrationSucceed',
                                color='blue darken-1',
                                text,
                                @click='step--'
                            ) {{ $tc('AddSellerProcess-vue.btns.back') }}
                            v-btn(
                                v-if='!createSellerLoading && integrationSucceed',
                                color='blue darken-1',
                                text,
                                @click='close(true)'
                            ) {{ $tc('AddSellerProcess-vue.btns.done') }}
</template>

<script lang="ts">
import { Component, Vue, Ref } from 'vue-property-decorator';
import addSeller, { AddSellerResp } from './AddSeller';
import addVendor, { AddVendorResp } from './AddVendor';
import UploadVendorProductsExcelModal from '@/components/excels/UploadVendorProductsExcelModal.vue';

import CountryFlag from 'vue-country-flag';
import { CountriesBackend } from '@/api/countries/countries';
import { components } from '@/api/models/Epinium';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import UserStore from '@/store/user.vuex';
import { AmazonRegions } from '@/api/amazon/amazon';

@Component<AddSellerProcess>({
    name: 'AddSellerProcess',
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage(
                    this.$t('AddSellerProcess-vue.errors.name').toString(),
                    required,
                ),
                minLength: helpers.withMessage(
                    this.$t('AddSellerProcess-vue.errors.name').toString(),
                    minLength(2),
                ),
            },
            integrationTypeSelected: {
                required,
            },
        };
    },
    components: {
        CountryFlag,
        UploadVendorProductsExcelModal,
    },
})
export default class AddSellerProcess extends Vue {
    // añadir seller
    @Ref() createUserForm!: Vue &
        Element & { validate(): boolean; reset(): boolean };
    public dialog = false;
    public step = 1;
    public regionSelected: AmazonRegions = AmazonRegions.europe;
    public countrySelected!: {
        name: string;
        value: string;
        props: components['schemas']['CountryDTO'];
    };
    public countries: Array<{
        name: string;
        value: string;
        code: string;
        props: components['schemas']['CountryDTO'];
    }> = [];
    public integrationTypeSelected: 'seller' | 'vendor' = 'seller';
    public name = '';
    public validateIntegration = false;
    public integrationAlreadyExists = false;
    public createSellerLoading = false;
    public integrationSucceed = false;
    public hasCredentialsToCheck = false;
    public uploadVendorProductsModalIsOpen = false;
    public vendorToUploadProducts:
        | components['schemas']['SellerDTO']
        | null
        | undefined = null;

    public async created() {
        const countries = await new CountriesBackend().getCountries();

        this.countries = countries
            .map((c) => {
                return {
                    name: this.$tc('countries.codes.' + c.code),
                    value: c._id as any,
                    code: c.code === 'uk' ? 'gb' : (c.code as string),
                    props: c,
                };
            })
            .filter((c) => c.code !== 'sa');
        this.countrySelected = this.countries[0];
    }

    public get regions() {
        return [
            {
                name: this.$tc('AddSellerProcess-vue.step-2.regions.europe'),
                value: AmazonRegions.europe,
            },
            {
                name: this.$tc('AddSellerProcess-vue.step-2.regions.america'),
                value: AmazonRegions.america,
            },
            {
                name: this.$tc('AddSellerProcess-vue.step-2.regions.farEast'),
                value: AmazonRegions.farEast,
            },
        ];
    }

    public get integrationTypes() {
        return [
            {
                name: this.$tc('AddSellerProcess-vue.step-1.seller'),
                value: 'seller',
            },
            {
                name: this.$tc('AddSellerProcess-vue.step-1.vendor'),
                value: 'vendor',
            },
        ];
    }

    public close(reload = false) {
        this.step = 1;
        this.regionSelected = AmazonRegions.europe;
        this.name = '';
        this.validateIntegration = false;
        this.integrationAlreadyExists = false;
        this.createSellerLoading = false;
        this.integrationSucceed = false;
        this.hasCredentialsToCheck = false;
        this.vendorToUploadProducts = null;
        this.uploadVendorProductsModalIsOpen = false;
        // descomprobamos todo
        this.v$.$reset();
        // quitamos el dialog
        this.dialog = false;
        // hacemos un emit para recargar la tabla
        if (reload) {
            let isVendor = false;
            if (this.integrationTypeSelected === 'vendor') {
                isVendor = true;
            }

            this.$emit('reload', this.integrationSucceed, isVendor);
        }
    }

    //** Función para abrir el popup de añadir seller en amazon */
    public async amazonSpPopup(type: 'seller' | 'vendor') {
        // save seller
        this.v$.$touch();
        this.integrationAlreadyExists = false;
        this.validateIntegration = false;
        if (!this.v$.$invalid) {
            this.createSellerLoading = true;
            let addSellerReponse: AddSellerResp | AddVendorResp;
            if (type === 'seller') {
                addSellerReponse = await addSeller({
                    name: this.name,
                    region: this.regionSelected,
                });
            } else {
                addSellerReponse = await addVendor({
                    name: this.name,
                    country: this.countrySelected.props,
                });
            }

            if (this.integrationTypeSelected === 'vendor') {
                this.vendorToUploadProducts = addSellerReponse.createdSeller;
            }

            this.validateIntegration = addSellerReponse.validateIntegration;
            this.integrationAlreadyExists =
                addSellerReponse.sellerErrors.integrationAlreadyExists;
            this.integrationSucceed = addSellerReponse.sellerIntegrated;
            // ponemos el loading a false
            this.createSellerLoading = false;
            // sdumammos un step
            this.step++;
            // si son las primewras credenciales añadimos unas conversiones
            if (addSellerReponse.firstCredential) {
                UserStore.tagManagerEvent('trial', {
                    type: 'Agency',
                });
            }
        }
    }

    public regionCountries() {
        const regionCountries: string[] = [];
        if (this.regionSelected === AmazonRegions.europe) {
            regionCountries.push(
                'es',
                'nl',
                'de',
                'fr',
                'gb',
                'it',
                'pl',
                'se',
                'in',
                'tr',
                'be',
                'ae',
                'eg',
                'sa',
            );
        } else if (this.regionSelected === AmazonRegions.america) {
            regionCountries.push('ca', 'mx', 'us', 'br');
        } else if (this.regionSelected === AmazonRegions.farEast) {
            regionCountries.push('jp', 'au', 'sg');
        }
        return regionCountries;
    }
}
</script>

<style lang="scss" scoped></style>
