import {
    GridApi,
    IGetRowsParams,
    NewValueParams,
} from '@ag-grid-community/all-modules';
import { components, paths } from '../models/Epinium';

import { API } from '../API';
export class SellersBackend extends API {
    public table(params: IGetRowsParams, gridApi: GridApi | null) {
        gridApi?.showLoadingOverlay();
        this.post('/sellers-crm/table', params).then((response) => {
            const sellers: components['schemas']['SellerHomeTableRespDTO'][] =
                response.data;
            params.successCallback(
                sellers,
                sellers.length < params.endRow
                    ? params.startRow + sellers.length
                    : -1,
            );
            gridApi?.hideOverlay();
        });
    }

    public getClientSellers(
        params?: paths['/sellers-crm/client-sellers']['get']['parameters']['query'],
    ): Promise<
        paths['/sellers-crm/client-sellers']['get']['responses']['200']['content']['application/json']
    > {
        return this.get('/sellers-crm/client-sellers', params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public updateName(p: NewValueParams) {
        if (p.oldValue !== p.newValue) {
            return this.post('/sellers-crm/change-name', {
                sellerID: p.data._id,
                name: p.newValue,
            });
        }
        return;
    }

    public updateState(p: NewValueParams) {
        if (p.oldValue !== p.newValue) {
            return this.post('/sellers-crm/change-state', {
                sellerID: p.data._id,
                active: p.newValue,
            });
        }
        return;
    }

    public removeSeller(sellerID: string): Promise<{ removing: boolean }> {
        return this.post('/sellers-crm/remove', {
            sellerID,
        }).then((s: any) => {
            return s.data;
        });
    }
    public refreshSeller(
        params: paths['/sellers-crm/refresh-seller-data']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/sellers-crm/refresh-seller-data']['post']['responses']['201']['content']['application/json']
    > {
        return this.post('/sellers-crm/refresh-seller-data', params).then(
            (s) => {
                return s.data;
            },
        );
    }

    public refreshVendor(
        params: paths['/vendors-crm/refresh-vendor-data']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/vendors-crm/refresh-vendor-data']['post']['responses']['201']['content']['application/json']
    > {
        return this.post('/vendors-crm/refresh-vendor-data', params).then(
            (s) => {
                return s.data;
            },
        );
    }

    public integrateSpSeller(
        params: components['schemas']['IntegrateSpSellerDTO'],
    ): Promise<components['schemas']['IntegrateSellerResponseDTO']> {
        return this.post(
            '/sellers-crm/integrate-new-sp-seller-step-2',
            params,
        ).then((s) => {
            return s.data;
        });
    }

    public integrateSpVendor(
        params: components['schemas']['IntegrateSpVendorDTO'],
    ): Promise<components['schemas']['IntegrateVendorResponseDTO']> {
        return this.post(
            '/vendors-crm/integrate-new-sp-vendor-step-2',
            params,
        ).then((s) => {
            return s.data;
        });
    }

    public prepareSellersSpAuth(
        params: paths['/sellers-crm/prepare-seller-sp-auth-call']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/sellers-crm/prepare-seller-sp-auth-call']['post']['responses']['201']['content']['application/json']
    > {
        return this.post(
            '/sellers-crm/prepare-seller-sp-auth-call',
            params,
        ).then((res) => res.data);
    }

    public prepareVendorsSpAuth(
        params: paths['/vendors-crm/prepare-vendor-sp-auth-call']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/vendors-crm/prepare-vendor-sp-auth-call']['post']['responses']['201']['content']['application/json']
    > {
        return this.post(
            '/vendors-crm/prepare-vendor-sp-auth-call',
            params,
        ).then((res) => res.data);
    }

    public setAdvCredential(
        params: paths['/sellers-crm/save-selected-vendor-adv-credentials']['post']['requestBody']['content']['application/json'],
    ) {
        return this.post(
            '/sellers-crm/save-selected-vendor-adv-credentials',
            params,
        );
    }
}

export default new SellersBackend();
