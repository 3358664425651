<template lang="pug">
.d-flex.align-baseline(style='width: 100%')
    div {{ getValue }}
    .caption.font-weight-bold.ml-3(
        v-if='getDiff',
        :class='getDiff > 0 ? "green--text" : "red--text"'
    ) {{ diffFormatter(getDiff) }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import property from 'lodash/property';

@Component<CurrencyDiffCellRender>({ name: 'CurrencyDiffCellRender' })
export default class CurrencyDiffCellRender
    extends Vue
    implements ICellRendererComp
{
    public params!: ICellRendererParams & { diff: string };
    public getGui!: any;

    public diff: { value: number; currency: string } = {
        value: 0,
        currency: '',
    };

    public created() {
        this.diff = property(this.params.diff)(this.params.data) as {
            value: number;
            currency: string;
        };
    }

    public refresh(params: ICellRendererParams & { diff: string }) {
        this.params = params;
        return false;
    }
    public get getValue() {
        return this.params.value
            ? Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: this.params.value.currency,
              }).format(this.params.value.value || 0)
            : '-';
    }
    public get getDiff() {
        if (this.params?.value?.value && this.diff?.value) {
            const d30 = this.params.value.value;
            const d60 = this.diff.value;
            const diff = ((d30 - d60) / (d30 + d60) || 1) * 100;
            return diff;
        }
        return '';
    }

    diffFormatter(diff: number) {
        if (!diff) return '-';
        return (
            Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 2,
            }).format(diff) + '%'
        );
    }
}
</script>

<style lang="scss" scoped></style>
