<template lang="pug">
v-container.pa-6(fluid)
    v-row
        v-col(cols='12', md='6')
            v-card
                v-card-title
                    span.headline {{ $t('UserSettings-vue.settings.user.header') }}
                v-card-text
                    v-container.bg-surface-variant
                        v-row(no-gutters='')
                            v-col {{ $t('UserSettings-vue.settings.user.name.placeholder') }}
                            v-col {{ $t('UserSettings-vue.settings.user.email.placeholder') }}
                            v-responsive(width='100%')
                            v-col {{ userInfo?.name }}
                            v-col {{ userInfo?.email }}
            // settings
            v-card.mt-5
                v-card-title
                    span.headline {{ $t('UserSettings-vue.settings.header') }}
                v-card-text
                    v-menu(open-on-hover, offset-y)
                        template(v-slot:activator='{ on, attrs }')
                            v-btn(v-bind='attrs', v-on='on')
                                v-icon mdi-translate
                                .ml-2 {{ $t('UserSettings-vue.' + $root.$i18n.locale) }}
                        v-list
                            v-list-item(@click='changeLocale("en")') {{ $t('UserSettings-vue.en') }}
                            v-list-item(@click='changeLocale("es")') {{ $t('UserSettings-vue.es') }}
                            v-list-item(@click='changeLocale("it")') {{ $t('UserSettings-vue.it') }}
                            v-list-item(@click='changeLocale("fr")') {{ $t('UserSettings-vue.fr') }}
                            v-list-item(@click='changeLocale("de")') {{ $t('UserSettings-vue.de') }}
        v-col(cols='12', md='6')
            PasswordChanger(:userID='userInfo && userInfo.userId')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { UsersBackend } from '../api/users/users';
import PasswordChanger from '../components/passwordChanger/PasswordChanger.vue';
import { components } from '@/api/models/Epinium';

@Component<UserSettings>({
    name: 'UserSettings',
    components: {
        PasswordChanger,
    },
})
export default class UserSettings extends Vue {
    public userInfo: components['schemas']['LoginResponseDTO'] | null = null;
    public name = '';
    public email = '';

    public async created() {
        this.getUserBasicInfo();
    }

    async changeLocale(locale: string) {
        if (this.userInfo && locale !== this.userInfo?.lang) {
            this.userInfo.lang = locale;
            this.$vuetify.lang.current = this.userInfo.lang;
            this.$root.$i18n.locale = this.userInfo.lang;
            this.$cookie.set('locale', this.userInfo.lang, { expires: '30D' });
            const usersBackend = new UsersBackend();
            await usersBackend.updateUserSettingsInfo({
                user: this.userInfo.id,
                update: { lang: this.userInfo.lang },
            });
        }
    }

    async getUserBasicInfo() {
        const usersBackend = new UsersBackend();
        const userBasicInfo: components['schemas']['LoginResponseDTO'] | null =
            await usersBackend.getUserBasicInfo().catch(() => {
                return null;
            });

        if (userBasicInfo) {
            this.userInfo = userBasicInfo;
            this.email = userBasicInfo.email;
            this.name = userBasicInfo.name;
        }
    }
}
</script>

<style lang="scss" scoped></style>
