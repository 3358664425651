import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';

import Vue from 'vue';

export default class IO {
    public io: Socket;

    constructor(
        subpath: string,
        options?: Partial<ManagerOptions & SocketOptions>,
    ) {
        this.io = io(process.env.VUE_APP_WS_API_URL ?? '', {
            path: (subpath.charAt(0) === '/' ? '' : '/') + subpath,
            reconnection: false,
            transports: ['websocket'],
            auth: { token: Vue.cookie.get('access') },
            ...options,
        });

        this.io.on('connect_error', (err) => {
            // eslint-disable-next-line
            console.error('connect_error', err);
        });
        this.io.on('connect_timeout', (err) => {
            // eslint-disable-next-line
            console.error('connect_timeout', err);
        });
        this.io.on('error', (err) => {
            // eslint-disable-next-line
            console.error('error', err);
        });
    }

    public async onConnect() {
        return new Promise<void>((res) => {
            this.io.on('connect', () => {
                return res();
            });
        });
    }

    public on(event: string, callback: (options: any) => any): void {
        this.io.on(event, callback);
    }

    public emit(event: string, ...args: any[]): void {
        this.io.emit(event, ...args);
    }

    public close() {
        // this.io.close();
        this.io.disconnect();
    }

    public off(event: string) {
        this.io.off(event);
    }
}
