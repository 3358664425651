<template lang="pug">
.d-flex(v-if='loaded')
    template(v-if='someFailed', top)
        // SI NO HAY CREDENCIALES ACTIVAS
        template(v-if='!stateParams.activeCredewntials')
            v-dialog(v-model='isOpen', scrollable, max-width='600px')
                template(v-slot:activator='{ on, attrs }')
                    v-tooltip(top)
                        template(v-slot:activator='{ on, attrs }')
                            v-icon(color='red', v-bind='attrs', v-on='on') mdi-alert-circle
                        span {{ $t('SellerStateRenderer-vue.renew-credentials.description', { renewCredentials: $t('SellerStateRenderer-vue.renew-credentials.btn') }) }}
                    v-btn.mx-2(
                        x-small,
                        color='primary',
                        @click='renewCredentials',
                        :disabled='renewLoading',
                        :loading='renewLoading'
                    ) {{ $tc('SellerStateRenderer-vue.renew-credentials.btn') }}
        // SI NO HAY ACCESO BRAND ANALYTICS
        template(v-else-if='!stateParams.brandAnalyticsAccess')
            v-dialog(v-model='isOpen', scrollable, max-width='600px')
                template(v-slot:activator='{ on, attrs }')
                    v-tooltip(top)
                        template(v-slot:activator='{ on, attrs }')
                            v-icon(color='red', v-bind='attrs', v-on='on') mdi-alert-circle
                        span {{ $t('SellerStateRenderer-vue.auth-brand-analytics.description', { renewCredentials: $t('SellerStateRenderer-vue.auth-brand-analytics.btn') }) }}
                    v-btn.mx-2(
                        x-small,
                        color='primary',
                        @click='renewCredentials',
                        :disabled='renewLoading',
                        :loading='renewLoading'
                    ) {{ $tc('SellerStateRenderer-vue.auth-brand-analytics.btn') }}
        // SI EL ACCESO ESTÁ DENEGADO
        template(v-else-if='mwsAccessDenied.length')
            v-dialog(v-model='isOpen', scrollable, max-width='600px')
                template(v-slot:activator='{ on, attrs }')
                    v-tooltip(top)
                        template(v-slot:activator='{ on, attrs }')
                            v-icon(color='yellow', v-bind='attrs', v-on='on') mdi-alert
                        span {{ $tc('SellerStateRenderer-vue.selling-partner-access-denied.credentials-failing') }}
                    v-btn(icon, color='primary', v-on='on', x-small)
                        v-icon mdi-eye
                v-card
                    v-simple-table
                        template(v-slot:default)
                            thead
                                tr
                                    th.text-left {{ $tc('SellerStateRenderer-vue.selling-partner-access-denied.country') }}
                                    th.text-left {{ $tc('SellerStateRenderer-vue.selling-partner-access-denied.since') }}
                                    th.text-left {{ $tc('SellerStateRenderer-vue.selling-partner-access-denied.last-try') }}
                            tbody
                                tr(v-for='(p, i) in mwsAccessDenied', :key='i')
                                    td {{ $tc(`countries.names.${p.country}`) }}
                                    td {{ dayjs(p.since).format($tc('SellerStateRenderer-vue.selling-partner-access-denied.date-format')) }}
                                    td {{ dayjs(p.lastIntent).format($tc('SellerStateRenderer-vue.selling-partner-access-denied.date-format')) }}
        // SI HAY CREDENCIALES DE ADV PARA VINCULAR
        template(v-else-if='!stateParams.advCredentialsToMatch && isVendor')
            v-dialog(v-model='isOpen', scrollable, max-width='600px')
                template(v-slot:activator='{ on, attrs }')
                    v-tooltip(top)
                        template(v-slot:activator='{ on, attrs }')
                            v-icon(color='yellow', v-bind='attrs', v-on='on') mdi-alert
                        span {{ $tc('SellerStateRenderer-vue.advertising-pending-to-link.tooltip') }}
                    v-btn.mx-2(
                        x-small,
                        color='primary',
                        @click='linkAdvertisingCredentials',
                        :disabled='linkAdvLoading',
                        :loading='linkAdvLoading'
                    ) {{ $tc('SellerStateRenderer-vue.advertising-pending-to-link.btn') }}
        // RESTO DE POSIBLES FALLOS
        template(v-else)
            v-tooltip(top)
                template(v-slot:activator='{ on, attrs }')
                    v-icon(color='red', v-bind='attrs', v-on='on') mdi-alert-circle
                span {{ warningDesciription }}
    // Si no hay alertas, sale icono verde
    v-icon(v-else, color='green') mdi-checkbox-marked-circle
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import dayjs from 'dayjs';
import addSeller, { AddSellerResp } from '../sellers/AddSeller';
import addVendor, { AddVendorResp } from '../sellers/AddVendor';
import { components } from '@/api/models/Epinium';
import VendorAdvertisingProfilesSelectorModal from '@/components/sellers/VendorAdvertisingProfilesSelectorModal.vue';
import { AmazonRegions } from '@/api/amazon/amazon';

@Component<SellerStateRenderer>({
    name: 'SellerStateRenderer',
    components: { VendorAdvertisingProfilesSelectorModal },
    watch: {
        advertisingLinkerModalOpened() {
            if (this.advertisingLinkerModalOpened === false)
                this.linkAdvLoading = false;
        },
    },
})
export default class SellerStateRenderer
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public dialog = false;
    public params!: ICellRendererParams & {
        addMessages: (message: string) => void;
        reloadTable: () => void;
        getAdvProfiles: (params: {
            sellerID: string;
            name: string;
            countryForVendor: string;
            advertisingCredentialToSeekProfile?: string;
        }) => void;
    };
    public stateParams = {
        activeCredewntials: false,
        validateMwsToken: false,
        mwsAccessDenied: false,
        valid: false,
        advCredentialsToMatch: false,
        brandAnalyticsAccess: false,
    };
    public loaded = false;
    public mwsAccessDenied: any = [];
    public isOpen = false;
    public dayjs = dayjs;
    public sellerID: string | undefined;
    public seller: components['schemas']['SellerTableRespDTO'] | any = {};
    public region!: AmazonRegions;
    public isVendor = false;
    public countries!: string[];
    public countriesObj!: components['schemas']['CountryDTO'][];
    public name: string | undefined;
    public renewLoading = false;
    public sellerId: string | undefined;
    public advertisingLinkerModalOpened = false;
    public linkAdvLoading = false;
    public advertisingCredentialToSeekProfiles: string | undefined;

    public created() {
        if (this.params.data) {
            this.seller = this.params.data;
            this.sellerId = this.params.data.sellerId;
            this.name = this.params.data.name;
            this.sellerID = this.params.data._id;
            this.region = this.params.data.region;
            this.isVendor = this.params.data.isVendor || false;
            this.countries = this.params.data.countries;
            this.countriesObj = this.params.data.countriesObj;
            this.mwsAccessDenied.push(...this.params.data.mwsAccessDenied);
            this.advertisingCredentialToSeekProfiles =
                this.params.data.advertisingCredentialToSeekProfiles;
            this.stateParams = {
                activeCredewntials: !this.params.data.renewCredentials,
                brandAnalyticsAccess:
                    this.params.data.accountPermissions?.brandAnalytics,
                validateMwsToken: this.params.data.validateMwsToken,
                mwsAccessDenied: !this.mwsAccessDenied.length,
                valid:
                    this.params.data.valid !== undefined
                        ? this.params.data.valid
                        : true,
                advCredentialsToMatch: !(
                    this.isVendor &&
                    this.params.data.advCredentialsToMatch &&
                    !this.params.data.advertisingIntegration
                ),
            };
            this.loaded = true;
        }
    }

    public get someFailed() {
        let someFail = false;
        for (const p of Object.values(this.stateParams)) {
            if (!p) {
                someFail = true;
                break;
            }
        }
        return someFail;
    }

    public get warningDesciription() {
        let description = '';
        if (this.stateParams.valid === false) {
            description = this.$tc('SellerStateRenderer-vue.invalid-seller');
        } else if (!this.stateParams.validateMwsToken) {
            description = this.$tc('SellerStateRenderer-vue.error-validating');
        }
        return description;
    }
    public refresh(
        params: ICellRendererParams & {
            addMessages: (message: string) => void;
            reloadTable: () => void;
            getAdvProfiles: (params: {
                sellerID: string;
                name: string;
                countryForVendor: string;
                advertisingCredentialToSeekProfile?: string;
            }) => void;
        },
    ) {
        this.params = params;
        return true;
    }

    public async linkAdvertisingCredentials() {
        this.advertisingLinkerModalOpened = true;
        this.linkAdvLoading = true;
        this.params.getAdvProfiles({
            sellerID: this.seller._id,
            name: this.name as string,
            countryForVendor: this.countries[0] as string,
            advertisingCredentialToSeekProfile:
                this.advertisingCredentialToSeekProfiles,
        });
    }

    public async renewCredentials() {
        if (this.name && this.sellerID && this.region) {
            this.renewLoading = true;
            let addSellerResponse: AddSellerResp | AddVendorResp | void;
            if (this.isVendor) {
                addSellerResponse = await addVendor({
                    name: this.name,
                    sellerID: this.sellerID,
                    country: this.countriesObj?.[0] ?? this.countries?.[0],
                }).catch(() => {
                    this.params.addMessages(
                        this.$t(
                            'SellerStateRenderer-vue.renew-credentials.success',
                            {
                                seller: this.name,
                            },
                        ).toString(),
                    );
                });
            } else {
                addSellerResponse = await addSeller({
                    name: this.name,
                    sellerID: this.sellerID,
                    region: this.region,
                }).catch(() => {
                    this.params.addMessages(
                        this.$t(
                            'SellerStateRenderer-vue.renew-credentials.success',
                            {
                                seller: this.name,
                            },
                        ).toString(),
                    );
                });
            }

            if (addSellerResponse) {
                if (addSellerResponse.hasCredentialsAccess) {
                    if (addSellerResponse.sellerIntegrated) {
                        this.params.addMessages(
                            this.$t(
                                'SellerStateRenderer-vue.renew-credentials.success',
                                {
                                    seller: this.name,
                                },
                            ).toString(),
                        );
                        this.params.reloadTable();
                    } else if (
                        addSellerResponse.sellerErrors
                            .revinculationNotSameSeller
                    ) {
                        this.params.addMessages(
                            this.$t(
                                'renew-credentials.not-same-seller-for-renew',
                                {
                                    seller: this.name,
                                    sellerId: this.sellerId,
                                },
                            ).toString(),
                        );
                    } else {
                        this.params.addMessages(
                            this.$t(
                                'SellerStateRenderer-vue.renew-credentials.error',
                                {
                                    seller: this.name,
                                },
                            ).toString(),
                        );
                    }
                }
            }

            this.renewLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped></style>
