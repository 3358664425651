export const ValidateSpanishID = (function () {
    'use strict';

    const DNI_REGEX = /^(\d{8})([A-Z])$/;
    const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

    const ValidateSpanishID = function (str: string) {
        // Ensure upcase and remove whitespace
        str = str.toUpperCase().replace(/\s/, '');

        let valid = false;
        const type = spainIdType(str);

        switch (type) {
            case 'dni':
                valid = validDNI(str);
                break;
            case 'nie':
                valid = validNIE(str);
                break;
            case 'cif':
                valid = validCIF(str);
                break;
        }

        return {
            type: type,
            valid: valid,
        };
    };

    const spainIdType = function (str: string) {
        if (str.match(DNI_REGEX)) {
            return 'dni';
        }
        if (str.match(CIF_REGEX)) {
            return 'cif';
        }
        if (str.match(NIE_REGEX)) {
            return 'nie';
        }
    };

    const validDNI = function (dni: string) {
        const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
        const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

        return letter == dni.charAt(8);
    };

    const validNIE = function (nie: string) {
        // Change the initial letter for the corresponding number and validate as DNI
        let nie_prefix = nie.charAt(0);

        switch (nie_prefix) {
            case 'X':
                nie_prefix = '0';
                break;
            case 'Y':
                nie_prefix = '1';
                break;
            case 'Z':
                nie_prefix = '2';
                break;
        }

        return validDNI(nie_prefix + nie.substr(1));
    };

    const validCIF = function (cif: string) {
        let valid = false;
        const match = cif.match(CIF_REGEX);
        if (match) {
            const letter = match[1];
            const number = match[2];
            const control = match[3];

            let even_sum = 0;
            let odd_sum = 0;
            let n;

            for (let i = 0; i < number.length; i++) {
                n = parseInt(number[i], 10);

                // Odd positions (Even index equals to odd position. i=0 equals first position)
                if (i % 2 === 0) {
                    // Odd positions are multiplied first.
                    n *= 2;

                    // If the multiplication is bigger than 10 we need to adjust
                    odd_sum += n < 10 ? n : n - 9;

                    // Even positions
                    // Just sum them
                } else {
                    even_sum += n;
                }
            }

            // const control_digit =
            //     10 - Number((even_sum + odd_sum).toString().substr(-1));
            const control_digit =
                (10 - Number((even_sum + odd_sum).toString().substr(-1))) % 10;
            const control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

            // Control must be a digit
            if (letter.match(/[ABEH]/)) {
                valid = +control == control_digit;

                // Control must be a letter
            } else if (letter.match(/[KPQS]/)) {
                valid = control == control_letter;

                // Can be either
            } else {
                valid = +control == control_digit || control == control_letter;
            }
        }
        return valid;
    };

    return ValidateSpanishID;
})();
