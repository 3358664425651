import Vue from 'vue';
import store from './store';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import VueCookie from 'vue-cookie';
import i18n from './i18n';
import Hotjar from 'vue-hotjar';
import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.config.productionTip = false;

// Plugins
Vue.use(VueCookie);
/** Hotjar */
Vue.use(Hotjar, {
    id: '782553', // Hotjar Site ID
    isProduction: process.env.NODE_ENV === 'production',
});
/** End Hotjar */

const options = {
    pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    apiVersion: '2020-08-27',
    locale: i18n.locale,
};

Vue.use(StripePlugin, options);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
