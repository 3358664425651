import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { light } from './theme';
import minifyTheme from 'minify-css-string';
import i18n from '@/i18n';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: { minifyTheme },
        themes: {
            light,
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params) as any,
    },
});
