<template lang="pug">
v-container(fluid)
    template(v-if='subscription && subscription.subscription')
        v-row
            v-col
                Subscription(
                    :subscription='subscription',
                    :dataCancelSubscription='dataCancelSubscription'
                )
            v-col
                PaymentMethod(:subscription='subscription')
    v-row.fill-height.mt-5(
        v-if='subscriptionLoading',
        align-content='center',
        justify='center'
    )
        v-col.text-center(cols='12')
            v-progress-circular(
                color='deep-purple accent-4',
                :size='70',
                indeterminate
            )
    Invoices
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Subscription from '@/components/billing/Subscription.vue';
import PaymentMethod from '@/components/billing/PaymentMethod.vue';
import Invoices from '@/components/billing/Invoices.vue';
import { components } from '@/api/models/Epinium';
import { ClientsBackend } from '@/api/clients/clients';
import ClientStore from '@/store/client.vuex';
import dayjs from 'dayjs';
@Component<Billing>({
    name: 'Billing',
    components: {
        Subscription,
        PaymentMethod,
        Invoices,
    },
})
export default class Billing extends Vue {
    public client = ClientStore.clientData;
    public subscription:
        | components['schemas']['SubscriptionResponseDTO']
        | null = null;
    public dataCancelSubscription: {
        lastDateUsePlatform: string;
        isPermanence: boolean;
    } | null = null;
    public subscriptionLoading = true;
    async created() {
        this.client = await ClientStore.getClient();
        if (this.client && !this.client.trial) {
            this.subscription = await new ClientsBackend().subscription();
            this.checkPermanenceCancelSubscription();
        }
        this.subscriptionLoading = false;
    }

    public checkPermanenceCancelSubscription() {
        let today = dayjs().startOf('day');
        const dateDeficiency = dayjs(
            this.client?.subscription?.permanence?.dateDeficiency,
        ).startOf('day');

        const datePermanence = dayjs(
            this.client?.subscription?.permanence?.datePermanence,
        ).startOf('day');

        const isPermanence =
            !(today.isBefore(dateDeficiency) || today.isSame(dateDeficiency)) &&
            (today.isBefore(datePermanence) || today.isSame(datePermanence));

        this.dataCancelSubscription = {
            lastDateUsePlatform: isPermanence
                ? dayjs(
                      this.client?.subscription?.permanence?.datePermanence,
                  ).format(this.$t('date-format-table').toString())
                : dayjs
                      .unix(
                          this.subscription?.incoming.next_payment_attempt || 0,
                      )
                      .format(this.$t('date-format-table').toString()),
            isPermanence,
        };
    }
}
</script>

<style lang="scss" scoped></style>
