<template lang="pug">
v-icon
    template(v-if='params.value') mdi-check
    template(v-else) mdi-close
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<BooleanCellRender>({ name: 'BooleanCellRender' })
export default class BooleanCellRender
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public params!: ICellRendererParams;
    public refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
}
</script>

<style lang="scss" scoped></style>
