<template lang="pug">
.d-flex.align-baseline
    div {{ getValue }}
    .caption.font-weight-bold.ml-3(
        v-if='getDiff',
        :class='getDiff < 0 ? "green--text" : "red--text"'
    ) {{ diffFormatter(getDiff) }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import property from 'lodash/property';

@Component<AcosDiffCellRender>({ name: 'AcosDiffCellRender' })
export default class AcosDiffCellRender
    extends Vue
    implements ICellRendererComp
{
    public params!: ICellRendererParams & {
        sales30: string;
        sales60: string;
        costs30: string;
        costs60: string;
    };
    public getGui!: any;

    public sales30 = 0;
    public sales60 = 0;
    public costs30 = 0;
    public costs60 = 0;

    public created() {
        this.sales30 = property(this.params.sales30)(
            this.params.data,
        ) as number;
        this.sales60 = property(this.params.sales60)(
            this.params.data,
        ) as number;
        this.costs30 = property(this.params.costs30)(
            this.params.data,
        ) as number;
        this.costs60 = property(this.params.costs60)(
            this.params.data,
        ) as number;
    }

    public refresh(
        params: ICellRendererParams & {
            sales30: string;
            sales60: string;
            costs30: string;
            costs60: string;
        },
    ) {
        this.params = params;
        return false;
    }
    public get getValue() {
        return this.costs30
            ? Intl.NumberFormat('de-DE', {
                  maximumFractionDigits: 2,
              }).format((this.costs30 / (this.sales30 || 1)) * 100) + '%'
            : '-';
    }
    public get getDiff() {
        if (this.costs30 && this.costs60) {
            const acos30 = (this.costs30 / (this.sales30 || 1)) * 100;
            const acos60 = (this.costs60 / (this.sales60 || 1)) * 100;
            return ((acos30 - acos60) / (acos30 + acos60) || 1) * 100;
        }
        return '';
    }

    diffFormatter(diff: number) {
        if (!diff) return '-';
        return (
            (diff > 0 ? '+' : '') +
            (Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 2,
            }).format(diff) +
                '%')
        );
    }
}
</script>

<style lang="scss" scoped></style>
