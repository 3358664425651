import {
    VuexModule,
    Module,
    Mutation,
    Action,
    config,
    getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { components } from '@/api/models/Epinium';
import { ClientsBackend } from '../api/clients/clients';
config.rawError = true;

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'client',
})
class ClientStore extends VuexModule {
    clientData: components['schemas']['ClientInfo'] | null = null;

    @Mutation
    setClient(client: components['schemas']['ClientInfo']) {
        this.clientData = client;
    }

    @Action({ commit: 'setClient' })
    async getClient(force = false) {
        const clientData =
            force || !this.clientData
                ? await new ClientsBackend().getClientInfo()
                : this.clientData;
        return clientData;
    }
}

export default getModule(ClientStore);
