import axios, {
    AxiosRequestConfig,
    AxiosResponse,
    Method,
    AxiosError,
    HttpStatusCode,
} from 'axios';
import Vue from 'vue';
import router from '@/router';
import { GridApi, IGetRowsParams } from '@ag-grid-community/all-modules';
export class API {
    protected get(
        url: string,
        data: { [key: string]: any } = {},
        headers = {},
    ): Promise<AxiosResponse> {
        return this.call('GET', url, data, headers);
    }

    protected post(
        url: string,
        data: { [key: string]: any } = {},
        headers = {},
    ) {
        return this.call('POST', url, data, headers);
    }

    private call(
        method: Method,
        url: string,
        data: { [key: string]: any } = {},
        headers = {},
    ) {
        const token = Vue.cookie.get('access');
        const config: AxiosRequestConfig = {
            url:
                process.env.VUE_APP_API_URL +
                (url.charAt(0) === '/' ? url.substring(1, url.length) : url),
            method,
            headers: {
                Authorization: 'Bearer ' + token,
                ...headers,
            },
        };

        switch (method) {
            case 'GET':
                config.params = data;
                break;
            default:
                config.data = data;
                break;
        }

        return axios.request(config).catch((error: AxiosError) => {
            // console.error('axios error: ', error);
            // Si ha expirado la cookie, lo controlamos y expulsamos al usuario al login
            if (error.response?.status === 401) {
                Vue.cookie.delete('access', { domain: 'epinium.com' });
                Vue.cookie.delete('access', { domain: 'localhost' });
                Vue.cookie.delete('access');
                router.push({ name: 'login' }); // Movemos al login
                throw error;
            }
            if (error.response) {
                if (
                    error.response.status === HttpStatusCode.ServiceUnavailable
                ) {
                    Vue.cookie.delete('access', { domain: 'epinium.com' });
                    Vue.cookie.delete('access', { domain: 'localhost' });
                    Vue.cookie.delete('access');
                    if (router.currentRoute.name !== 'maintenance') {
                        router.push({ name: 'maintenance' });
                    }
                }
                throw error;
            } else {
                throw `404 ${url} not found`;
            }
        });
    }
    // eslint-disable-next-line
    public table(params: IGetRowsParams, gridApi: GridApi | null): void {}
}
