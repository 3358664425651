<template lang="pug">
v-container.pa-0(fluid)
    VendorAdvertisingProfilesSelectorModal(
        v-if='selectedVendorIDForAdvertisingLinking',
        :modalIsOpen.sync='advertisingProfilesLinkingModalOpened',
        :sellerID='selectedVendorIDForAdvertisingLinking',
        :advProfilesToSelect='vendorAdvertisingProfilesForLinking',
        :advCredentialID='advCredentialID',
        :checkedAdv='checked'
    )
    SellerIntegrationSuccessPopup(
        :open.sync='displaySellerIntegrationSuccesPopup'
    )
    VendorIntegrationSuccessPopup(
        :open.sync='displayVendorIntegrationSuccesPopup'
    )

    AgGrid(
        width='100%',
        :provider='tableProvider',
        :columnDefs='columnDefs',
        ref='sellerTable',
        :height='`${tableHeight}px`'
    )
        template(slot='right')
            AddSellerProcess(@reload='reload')
                template(slot='left')
                    v-btn.ml-auto.mr-5(
                        color='info',
                        :href='$t("sellers.demo-link")',
                        target='_blank'
                    ) {{ $t('sellers.request-demo') }}
    v-container
        .d-flex.justify-center
            .subtitle-1.pt20 {{ $t('sellers.trusted.text') }}
        .d-flex.justify-center.align-center
            .d-flex
                a(:href='$t("sellers.advanced-partner.url")', target='_blank')
                    v-img(
                        :src='$t("sellers.advanced-partner.img")',
                        style='width: 100px'
                    )
            .d-flex
                a(:href='$t("sellers.trusted.url")', target='_blank')
                    v-img.amz-images(:src='$t("sellers.trusted.img")')
    v-snackbars(
        :messages.sync='messages',
        color='primary',
        :timeout='-1',
        bottom,
        center
    )
        template(v-slot:action='{ close }')
            v-btn(text, @click='close()') {{ $t('Sellers-vue.snackbar.close') }}
</template>

<script lang="ts">
import { Component, Vue, Ref } from 'vue-property-decorator';
import { SellersBackend } from '@/api/sellers/sellers';
import {
    ColDef,
    ColGroupDef,
    ICellRendererParams,
    NewValueParams,
} from '@ag-grid-community/all-modules';
import AgGrid from '@/components/agGrid.vue';
import AsignedUsersEditor from '@/components/agGridEditors/sellers/AsignedUsersEditor.vue';
import RemoveSeller from '@/components/sellers/RemoveSeller.vue';
import { UsersBackend } from '../api/users/users';
import AddSellerProcess from '@/components/sellers/AddSellerProcess.vue';
import RefreshAmazonData from '@/components/sellers/RefreshAmazonData.vue';
import SellerAdvertisingCredentialsRenderer from '@/components/agGridRenders/SellerAdvertisingCredentialsRenderer.vue';
import IO from '@/utils/IO';
import VSnackbars from 'v-snackbars';
import SellerState from '@/components/agGridRenders/SellerStateRenderer.vue';
import UploadVendorExcelButton from '@/components/sellers/UploadVendorExcelButton.vue';
import { components } from '@/api/models/Epinium';
import VendorAdvertisingProfilesSelectorModal from '@/components/sellers/VendorAdvertisingProfilesSelectorModal.vue';
import SellerIntegrationSuccessPopup from '@/components/sellers/SellerIntegrationSuccessPopup.vue';
import VendorIntegrationSuccessPopup from '@/components/sellers/VendorIntegrationSuccessPopup.vue';

@Component<Sellers>({
    name: 'Sellers',
    components: {
        AgGrid,
        AddSellerProcess,
        VSnackbars,
        VendorAdvertisingProfilesSelectorModal,
        SellerIntegrationSuccessPopup,
        VendorIntegrationSuccessPopup,
    },
    watch: {
        advertisingProfilesLinkingModalOpened() {
            if (this.advertisingProfilesLinkingModalOpened === false) {
                this.reload();
            }
        },
    },
})
export default class Sellers extends Vue {
    public tableProvider = SellersBackend;
    public snackbar = false;
    public messages: string[] = [];
    public advertisingProfilesLinkingModalOpened = false;
    public selectedVendorIDForAdvertisingLinking:
        | components['schemas']['SellerTableRespDTO']['_id']
        | any = {};

    public vendorAdvertisingProfilesForLinking: components['schemas']['GetAdvertisingProfilesResponseDTO']['profiles'] =
        [];
    public checked = false;
    public advCredentialID: any = null;

    //Integration popups
    public displaySellerIntegrationSuccesPopup = false;
    public displayVendorIntegrationSuccesPopup = false;

    @Ref('sellerTable') public sellerTable!: AgGrid;
    public get columnDefs(): Array<ColDef | ColGroupDef> {
        return [
            {
                headerName: this.$tc('Sellers-vue.name'),
                headerTooltip: this.$tc('Sellers-vue.name'),
                field: 'name',
                sortable: true,
                type: 'text',
                editable: true,
                onCellValueChanged: (p: NewValueParams) =>
                    new this.tableProvider().updateName(p),
            },
            // {
            //     headerName: this.$tc('Sellers-vue.active'),
            //     headerTooltip: this.$tc('Sellers-vue.active'),
            //     field: 'active',
            //     sortable: true,
            //     editable: true,
            //     type: 'boolean',
            //     width: 80,
            //     onCellValueChanged: (p: NewValueParams) =>
            //         new this.tableProvider().updateState(p),
            // },
            // {
            //     headerName: this.$tc('Sellers-vue.dynamic-search-terms'),
            //     headerTooltip: this.$tc('Sellers-vue.dynamic-search-terms'),
            //     field: 'dynamicSearchTerms',
            //     sortable: true,
            //     editable: true,
            //     type: 'boolean',
            //     width: 110,
            //     onCellValueChanged: (p: NewValueParams) =>
            //         new this.tableProvider().updateDynamicSearchTerms(p),
            // },
            {
                headerName: this.$tc('Sellers-vue.users'),
                headerTooltip: this.$tc('Sellers-vue.name'),
                field: 'users',
                cellRenderer: (p: ICellRendererParams) =>
                    p.value ? p.value.map((a: any) => a.name).join(', ') : '-',
                cellEditorFramework: AsignedUsersEditor,
                onCellValueChanged: (p: NewValueParams) =>
                    new UsersBackend().updateAssignedUsers(p),
                editable: true,
                sortable: true,
                type: 'text',
            },
            {
                headerName: this.$tc('Sellers-vue.state'),
                headerTooltip: this.$tc('Sellers-vue.state'),
                width: 140,
                editable: false,
                cellRendererFramework: SellerState,
                cellRendererParams: {
                    reloadTable: this.reload,
                    addMessages: this.addMessages,
                    getAdvProfiles: this.getAdvProfiles,
                },
            },
            {
                headerName: this.$tc(
                    'Sellers-vue.advertisingIntegration.title',
                ),
                headerTooltip: this.$tc(
                    'Sellers-vue.advertisingIntegration.title',
                ),
                field: 'advertisingIntegration',
                width: 155,
                cellRendererFramework: SellerAdvertisingCredentialsRenderer,
                cellRendererParams: {
                    checkAdvertisingCredential: this.checkAdvertisingCredential,
                    getAdvProfiles: this.getAdvProfiles,
                    reloadTable: this.reload,
                },
            },
            {
                headerName: this.$tc('Sellers-vue.actions.add-vendor-products'),
                headerTooltip: this.$tc(
                    'Sellers-vue.actions.add-vendor-products',
                ),
                field: '_id',
                width: 90,
                cellRendererFramework: UploadVendorExcelButton,
            },
            {
                headerName: this.$tc('Sellers-vue.actions.refresh'),
                headerTooltip: this.$tc('Sellers-vue.actions.refresh'),
                field: '_id',
                width: 90,
                cellRendererParams: {
                    refresh: this.refreshAccount,
                },
                cellRendererFramework: RefreshAmazonData,
            },
            {
                headerName: this.$tc('Sellers-vue.actions.remove'),
                headerTooltip: this.$tc('Sellers-vue.actions.remove'),
                field: '_id',
                width: 90,
                cellRendererParams: {
                    delete: this.deleteSeller,
                },
                cellRendererFramework: RemoveSeller,
            },
        ];
    }
    public checkingAdvSellerIDS: string[] = [];

    public delAdvSellerIDS(sellerID: string) {
        const index = this.checkingAdvSellerIDS.indexOf(sellerID);
        if (index !== -1) {
            this.checkingAdvSellerIDS.splice(index, 1);
        }
    }
    public reload(integrationSucceed?: boolean, isVendor?: boolean) {
        // Mostrar Popup
        if (integrationSucceed) {
            if (isVendor) {
                this.displayVendorIntegrationSuccesPopup = true;
            } else {
                this.displaySellerIntegrationSuccesPopup = true;
            }
        }
        this.sellerTable.reloadTable();
    }
    public async deleteSeller(sellerID: string) {
        await new this.tableProvider().removeSeller(sellerID);
        this.reload();
    }
    public async refreshAccount(
        seller: components['schemas']['SellerTableRespDTO'],
    ) {
        if (seller.isVendor) {
            await new this.tableProvider().refreshVendor({
                vendorID: seller._id as string,
            });
        } else {
            await new this.tableProvider().refreshSeller({
                sellerID: seller._id as string,
            });
        }
        this.reload();
    }
    public addMessages(message: string) {
        this.messages.push(message);
    }

    public async getAdvProfiles(params: {
        sellerID: string;
        name: string;
        countryForVendor: string;
        advertisingCredentialToSeekProfile?: string;
    }) {
        // reiniciamos las variables
        this.vendorAdvertisingProfilesForLinking = [];
        this.advCredentialID = null;
        this.checked = false;
        // buscamos las credenciales de publicidad
        this.selectedVendorIDForAdvertisingLinking = params.sellerID;
        this.advertisingProfilesLinkingModalOpened = true;
        if (!this.checkingAdvSellerIDS.includes(params.sellerID)) {
            this.checkingAdvSellerIDS.push(params.sellerID);
            const sellerSocket = new IO('sellers', {
                forceNew: true,
            });

            sellerSocket.onConnect().then(() => {
                const getAdvProfilesParams: components['schemas']['GetAdvertisingProfilesDTO'] =
                    {
                        sellerID: params.sellerID,
                        country: params.countryForVendor,
                        advertisingCredentialToSeekProfileID:
                            params.advertisingCredentialToSeekProfile,
                    };
                sellerSocket.emit('get-adv-profiles', getAdvProfilesParams);
                sellerSocket.on(
                    'get-adv-profiles-finished',
                    (
                        advProfiles: components['schemas']['GetAdvertisingProfilesResponseDTO'],
                    ) => {
                        this.advCredentialID =
                            advProfiles.advertisingCredentialToSeekProfileID;
                        this.vendorAdvertisingProfilesForLinking =
                            advProfiles.profiles;
                        this.checked = true;
                        // lo quitamos del array checkingAdvSellerIDS
                        this.delAdvSellerIDS(params.sellerID);
                        sellerSocket.close();
                        return advProfiles;
                    },
                );
            });
        }
    }

    public async checkAdvertisingCredential(params: {
        sellerID: string;
        name: string;
    }) {
        if (!this.checkingAdvSellerIDS.includes(params.sellerID)) {
            this.checkingAdvSellerIDS.push(params.sellerID);
            const sellerSocket = new IO('sellers', {
                forceNew: true,
            });
            sellerSocket.onConnect().then(() => {
                sellerSocket.emit('refresh-adv-credentials', {
                    sellerID: params.sellerID,
                });
                sellerSocket.on(
                    'refresh-adv-credentials-finished',
                    (checkCredentials: {
                        hasCredential: boolean;
                        needToCheck: boolean;
                    }) => {
                        if (checkCredentials && checkCredentials.needToCheck) {
                            setTimeout(() => {
                                sellerSocket.emit('refresh-adv-credentials', {
                                    sellerID: params.sellerID,
                                });
                            }, 10000);
                        } else {
                            // lo quitamos del array checkingAdvSellerIDS
                            this.delAdvSellerIDS(params.sellerID);
                            // notificamos al usuario que se han cogido o no las credentiales
                            const messageText =
                                checkCredentials &&
                                checkCredentials.hasCredential
                                    ? this.$t(
                                          'Sellers-vue.advertisingIntegration.credentials-obtained',
                                          { seller: params.name },
                                      ).toString()
                                    : this.$t(
                                          'Sellers-vue.advertisingIntegration.credentials-not-obtained',
                                          { seller: params.name },
                                      ).toString();
                            this.messages.push(messageText);
                            sellerSocket.close();
                            this.reload();
                        }
                    },
                );
            });
        }
    }
    public tableHeight = 400;
    changeTableHeight() {
        this.tableHeight = window.innerHeight - 200 ?? 400;
    }
    public async mounted() {
        this.changeTableHeight();
        window.onresize = this.changeTableHeight;
    }
}
</script>

<style lang="scss" scoped>
.amz-images {
    max-width: 150px;
}
</style>
