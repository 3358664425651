import { AmazonRegions } from '@/api/amazon/amazon';
import { SellersBackend } from '@/api/sellers/sellers';
import { components } from '@/api/models/Epinium';
export type AddSellerResp = {
    sellerErrors: {
        uknownError: boolean;
        integrationAlreadyExists: boolean;
        revinculationNotSameSeller: boolean;
    };
    validateIntegration: boolean;
    sellerIntegrated: boolean;
    hasCredentialsToCheck: boolean;
    firstCredential: boolean;
    hasCredentialsAccess: boolean;
    createdSeller?: components['schemas']['SellerDTO'];
};
export default function addSeller(params: {
    name: string;
    region: AmazonRegions;
    sellerID?: string;
}) {
    return new Promise<AddSellerResp>((resolve) => {
        const sellerResp: AddSellerResp = {
            sellerErrors: {
                uknownError: false,
                integrationAlreadyExists: false,
                revinculationNotSameSeller: false,
            },
            validateIntegration: false,
            sellerIntegrated: false,
            hasCredentialsToCheck: false,
            firstCredential: false,
            hasCredentialsAccess: false,
        };

        try {
            new SellersBackend()
                .prepareSellersSpAuth({
                    sellerName: params.name,
                    region: params.region,
                    sellerID: params.sellerID,
                })
                .then((authRequest) => {
                    const connection: any = window.open(
                        authRequest.url,
                        'Authentication with Amazon',
                        'width=600, height=650,status=0, toolbar=0, location=no',
                    );
                    const timer = setInterval(async () => {
                        if (connection.closed) {
                            clearInterval(timer);
                            // actualizaremos el componente para que muestre que está cargando
                            const addSellerReponse = await new SellersBackend()
                                .integrateSpSeller({
                                    state: authRequest.state,
                                    renewCredentials: !!params.sellerID,
                                })
                                .catch(() => {
                                    sellerResp.sellerErrors.uknownError = true;
                                });
                            if (addSellerReponse) {
                                sellerResp.hasCredentialsAccess =
                                    addSellerReponse.hasCredentialsAccess;
                                sellerResp.validateIntegration =
                                    addSellerReponse.validateIntegration;
                                if (sellerResp.validateIntegration) {
                                    sellerResp.sellerIntegrated =
                                        addSellerReponse.sellerIntegrated;
                                    sellerResp.hasCredentialsToCheck =
                                        addSellerReponse.hasCredentialsToCheck;
                                    sellerResp.firstCredential =
                                        addSellerReponse.firstIntegration;
                                } else {
                                    // si es una revinculacion miramos si el revinculationNotSameSeller es true
                                    sellerResp.sellerErrors.revinculationNotSameSeller =
                                        !!addSellerReponse.revinculationNotSameSeller;
                                    sellerResp.sellerErrors.integrationAlreadyExists =
                                        addSellerReponse.alreadyExists;
                                    // si entramos aqui y ningun error es true, ponemos el uknown a true
                                    sellerResp.sellerErrors.uknownError =
                                        !Object.values(sellerResp).some(
                                            (s) => s,
                                        );
                                }
                            } else {
                                sellerResp.sellerErrors.uknownError = true;
                            }
                            resolve(sellerResp);
                        }
                    }, 1000);
                });
        } catch (err) {
            sellerResp.sellerErrors.uknownError = true;
            resolve(sellerResp);
        }
    });
}
