<template lang="pug">
div {{ getValue }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<CurrencyCellRender>({ name: 'CurrencyCellRender' })
export default class CurrencyCellRender
    extends Vue
    implements ICellRendererComp
{
    public params!: ICellRendererParams;
    public getGui!: any;
    public refresh(params: ICellRendererParams) {
        this.params = params;
        return false;
    }
    public get getValue() {
        if (this.params.value?.includes('admin'))
            return this.$t('PermissionsCellRender-vue.admin');
        if (this.params.value?.includes('agency-client'))
            return this.$t('PermissionsCellRender-vue.client');
        return this.$t('PermissionsCellRender-vue.worker');
    }
}
</script>

<style lang="scss" scoped></style>
