import { API } from '../API';
import { paths } from '../models/Epinium';
export class LoginBackend extends API {
    login(
        params: paths['/login']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/login']['post']['responses']['201']['content']['application/json']
    > {
        return this.post(`/login`, params).then((p) => p.data);
    }
}
