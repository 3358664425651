<template lang="pug">
v-dialog(v-model='opened', :width='900')
    upload-vendor-products-excel(:vendor='vendor')
    v-card
        v-card-actions
            v-spacer
            v-btn.mla.mr15(@click='opened = false') {{ $t('close') }}
</template>

<script lang="ts">
import { components } from '@/api/models/Epinium';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import UploadVendorProductsExcel from '@/components/excels/UploadVendorProdutsExcel.vue';

@Component<UploadVendorProductsExcelModal>({
    name: 'UploadVendorProductsExcelModal',
    props: {
        vendor: { required: true },
    },
    components: {
        UploadVendorProductsExcel,
    },
})
export default class UploadVendorProductsExcelModal extends Vue {
    public vendor!: components['schemas']['SellerTableRespDTO'];
    @PropSync('modalIsOpen') public opened!: boolean;
    @Prop() public rawProfiles!: boolean;
}
</script>

<style lang="scss"></style>
