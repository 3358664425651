<template lang="pug">
v-dialog(
    v-model='dialog',
    persistent,
    max-width='600px',
    transition='dialog-bottom-transition'
)
    template(v-slot:activator='{ on }')
        span(v-on='on') {{ $tc('CreateUser-vue.create-user.title') }}
    v-card
        v-card-title
            span.headline {{ $tc('CreateUser-vue.create-user.title') }}
        v-card-text
            v-form
                v-row
                    v-col(cols='12', sm='6')
                        v-text-field(
                            v-model='v$.name.$model',
                            :label='$t("CreateUser-vue.create-user.first-name")',
                            required,
                            :error-messages='v$.name.$errors.map((e) => e.$message)'
                        )
                    v-col(cols='12', sm='6')
                        v-text-field(
                            v-model='v$.lastName.$model',
                            :label='$t("CreateUser-vue.create-user.last-name")',
                            required,
                            :error-messages='v$.lastName.$errors.map((e) => e.$message)'
                        )
                    v-col(cols='12')
                        v-text-field(
                            v-model='v$.email.$model',
                            :label='$t("CreateUser-vue.create-user.email")',
                            required,
                            :error-messages='v$.email.$errors.map((e) => e.$message)'
                        )
                    v-col(cols='12')
                        vue-tel-input(
                            v-model='phone',
                            @country-changed='countrySelected',
                            :class='{ dark: $vuetify.theme.dark }'
                        )
                            template(v-slot:arrow-icon)
                                v-icon mdi-menu-down
                                strong +{{ countryCode }}
                    v-col(cols='12', sm='6')
                        v-text-field(
                            v-model='v$.password.$model',
                            :label='$t("CreateUser-vue.create-user.password")',
                            type='password',
                            required,
                            counter='30',
                            :error-messages='v$.password.$errors.map((e) => e.$message)'
                        )
                    v-col(cols='12', sm='6')
                        v-text-field(
                            v-model='v$.passwordConfirmation.$model',
                            :label='$t("CreateUser-vue.create-user.retype-password")',
                            type='password',
                            required,
                            counter='30',
                            :error-messages='v$.passwordConfirmation.$errors.map((e) => e.$message)'
                        )
                    v-col(cols='12', sm='6')
                        v-select(
                            v-model='v$.langSelected.$model',
                            :items='language',
                            item-text='name',
                            item-value='value',
                            :label='$t("CreateUser-vue.create-user.lang.title")'
                        )
                    v-col(cols='12', sm='6')
                        v-select(
                            v-model='permissions',
                            :items='permissionsAvaliables',
                            item-text='name',
                            item-value='value',
                            :label='$t("CreateUser-vue.create-user.permissions.title")',
                            :multiple='true'
                        )
                    v-col(cols='12', sm='6')
                        v-switch(
                            :label='$t("CreateUser-vue.create-user.offers")',
                            v-model='acceptOffers'
                        )
                    v-col(cols='12', sm='6')
                        v-switch(
                            v-model='v$.acceptTerms.$model',
                            :error-messages='v$.acceptTerms.$errors.map((e) => e.$message)'
                        )
                            div(slot='label')
                                | {{ $tc('CreateUser-vue.create-user.terms.accept') }}
                                a(
                                    :href='$t("CreateUser-vue.create-user.terms.url")'
                                ) {{ $tc('CreateUser-vue.create-user.terms.title') }}
                                | {{ $tc('CreateUser-vue.create-user.terms.and') }}
                                a(
                                    :href='$t("CreateUser-vue.create-user.privacy.url")'
                                ) {{ $tc('CreateUser-vue.create-user.privacy.title') }}
                small {{ $tc('CreateUser-vue.create-user.required') }}
                v-alert(
                    v-if='alreadyExists',
                    border='right',
                    colored-border,
                    type='error',
                    elevation='2'
                ) {{ $t('CreateUser-vue.errors.save.already-exists') }}
                v-alert(
                    v-if='unknownError',
                    border='right',
                    colored-border,
                    type='error',
                    elevation='2'
                ) {{ $t('CreateUser-vue.errors.save.unknown') }}

        v-card-actions
            v-spacer
            v-btn(color='blue darken-1', text='', @click='close') {{ $tc('CreateUser-vue.close') }}
            v-btn(color='blue darken-1', text='', @click='submitCreateUser') {{ $tc('CreateUser-vue.save') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { UsersBackend } from '@/api/users/users';
import { Permissions } from '@/api/users/dto/user.dto';
import { VueTelInput } from 'vue-tel-input';
import { components } from '@/api/models/Epinium';
import useVuelidate from '@vuelidate/core';
import {
    helpers,
    required,
    email,
    minLength,
    maxLength,
    sameAs,
} from '@vuelidate/validators';

@Component<CreateUser>({
    name: 'CreateUser',
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage(
                    this.$t('CreateUser-vue.errors.email.required').toString(),
                    required,
                ),
                email: helpers.withMessage(
                    this.$t('CreateUser-vue.errors.email.no-valid').toString(),
                    email,
                ),
            },
            name: {
                required: helpers.withMessage(
                    this.$t('CreateUser-vue.errors.name').toString(),
                    required,
                ),
                minLength: helpers.withMessage(
                    this.$t('CreateUser-vue.errors.name').toString(),
                    minLength(2),
                ),
            },
            lastName: {
                required: helpers.withMessage(
                    this.$t('CreateUser-vue.errors.name').toString(),
                    required,
                ),
                minLength: helpers.withMessage(
                    this.$t('CreateUser-vue.errors.name').toString(),
                    minLength(2),
                ),
            },
            langSelected: {
                required,
            },
            acceptTerms: {
                checked: helpers.withMessage(
                    this.$t(
                        'CreateUser-vue.errors.acceptTerms.checked',
                    ).toString(),
                    (acceptTerms: boolean) => acceptTerms === true,
                ),
            },
            password: {
                maxLength: helpers.withMessage(
                    this.$tc(
                        'CreateUser-vue.errors.passwords.maxlength',
                    ).toString(),
                    maxLength(30),
                ),
                minLength: helpers.withMessage(
                    this.$tc(
                        'CreateUser-vue.errors.passwords.minlength',
                    ).toString(),
                    minLength(8),
                ),
                required: helpers.withMessage(
                    this.$t(
                        'CreateUser-vue.errors.passwords.password.required',
                    ).toString(),
                    required,
                ),
            },
            passwordConfirmation: {
                required: helpers.withMessage(
                    this.$t(
                        'CreateUser-vue.errors.passwords.confirm-password.required',
                    ).toString(),
                    required,
                ),
                sameAsPassword: helpers.withMessage(
                    this.$t(
                        'CreateUser-vue.errors.passwords.confirm-password.notmatch',
                    ).toString(),
                    sameAs(this.password),
                ),
            },
        };
    },
    components: { VueTelInput },
})
export default class CreateUser extends Vue {
    public dialog = false;
    //form
    public email = '';
    public name = '';
    public lastName = '';
    public password = '';
    public passwordConfirmation = '';
    public langSelected = 'en';
    public permissions: Permissions[] = [];
    public acceptTerms = false;
    public acceptOffers = false;
    public phone = '';
    public countryCode: any = null;
    public alreadyExists = false;
    public unknownError = false;

    public get language() {
        return [
            {
                name: this.$tc('CreateUser-vue.create-user.lang.es'),
                value: 'es',
            },
            {
                name: this.$tc('CreateUser-vue.create-user.lang.en'),
                value: 'en',
            },
        ];
    }

    public countrySelected(val: any) {
        this.countryCode = val.dialCode;
    }

    public get permissionsAvaliables() {
        return [
            {
                name: this.$tc('CreateUser-vue.create-user.permissions.admin'),
                value: 'admin',
            },
        ];
    }

    public async submitCreateUser() {
        // ponemos los errores del request a false
        this.unknownError = false;
        this.alreadyExists = false;
        // hacemos que se toque todo el formulario para ver si hay algo invalido
        this.v$.$touch();
        if (!this.v$.$invalid) {
            const now = new Date().toJSON();
            const newUser: components['schemas']['CreateUserCRMDTO'] = {
                initialClientUser: false,
                active: true,
                email: this.email,
                name: this.name,
                lastName: this.lastName,
                permissions: [...this.permissions],
                offers: { accept: this.acceptOffers, updated: now },
                terms: { accept: this.acceptTerms, updated: now },
                lang: this.langSelected,
                auth: [
                    {
                        password: this.password,
                        email: this.email,
                        provider: 'epinium',
                        fingerPrint: '',
                    },
                ],
                phone:
                    this.phone.length > 2
                        ? (this.countryCode
                              ? '+' + this.countryCode
                              : undefined) + this.phone.replace(/ /g, '').trim()
                        : undefined,
                sellers: [],
                countries: [],
            };
            const usersBackend = new UsersBackend();
            const creatUserResp = await usersBackend
                .createUser(newUser)
                .catch(() => {
                    this.unknownError = true;
                });
            if (creatUserResp) {
                if ('created' in creatUserResp) {
                    this.close();
                } else if ('alreadyExists' in creatUserResp) {
                    this.alreadyExists = true;
                }
            } else {
                this.unknownError = true;
            }
        }
    }

    public close() {
        this.email = '';
        this.name = '';
        this.lastName = '';
        this.password = '';
        this.passwordConfirmation = '';
        this.langSelected = 'en';
        this.permissions = [];
        this.acceptTerms = false;
        this.acceptOffers = false;
        this.phone = '';
        this.countryCode = null;
        this.unknownError = false;
        this.alreadyExists = false;
        // descomprobamos todo
        this.v$.$reset();

        // quitamos el dialog
        this.dialog = false;

        // hacemos un emit para recargar la tabla
        this.$emit('reload');
    }
}
</script>

<style lang="scss">
@import '~vue-tel-input/dist/vue-tel-input.css';
.vue-tel-input.dark {
    &:focus-within {
        box-shadow: none;
    }
    color: inherit;
    border: inherit;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.7);
    border-radius: 0;
    .vti__input {
        color: inherit;
    }
    .vti__dropdown.open,
    .vti__dropdown:hover,
    .vti__dropdown-list {
        background: #1e1e1e;
        color: #fff;
        border: none;
        box-shadow:
            0 5px 5px -3px rgb(0 0 0 / 20%),
            0 8px 10px 1px rgb(0 0 0 / 14%),
            0 3px 14px 2px rgb(0 0 0 / 12%);
    }
}
</style>
