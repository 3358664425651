import { API } from '../API';
import { paths } from '../models/Epinium';

export class ProductsBackend extends API {
    public async readVendorProductsExcel(
        params: paths['/products-crm/read-vendor-products-excel']['post']['requestBody']['content']['multipart/form-data'],
    ): Promise<
        paths['/products-crm/read-vendor-products-excel']['post']['responses']['200']['content']['application/json']
    > {
        return (
            await this.post(
                '/products-crm/read-vendor-products-excel',
                params,
                {
                    'Content-Type': 'multipart/form-data',
                },
            )
        ).data;
    }
}

export default new ProductsBackend();
