import { API } from '../API';
import { paths } from '../models/Epinium';
import { UpdateClientInfoDTO } from './dto/client-info.dto';
export class ClientsBackend extends API {
    public async getClientInfo(): Promise<
        paths['/clients-crm/client-info']['get']['responses']['200']['content']['application/json']
    > {
        return this.get('/clients-crm/client-info')
            .then((res) => {
                if (res.data) {
                    return res.data;
                } else {
                    throw {
                        err: 'no_data',
                        message: 'No data getting client info',
                    };
                }
            })
            .catch((err) => {
                throw err;
            });
    }

    public async getClienAdvertisingCredentials(): Promise<any> {
        return this.get('/clients-crm/adv-credentials').then((res) => {
            return res && res.data ? res.data : [];
        });
    }

    public async changeClientInfo(update: UpdateClientInfoDTO): Promise<any> {
        return this.post('/clients-crm/update-client-info', update);
    }

    public async uploadLogo(
        params: paths['/clients-crm/uploadLogo']['post']['requestBody']['content']['multipart/form-data'],
    ) {
        await this.post('/clients-crm/uploadLogo', params, {
            'Content-Type': 'multipart/form-data',
        });
    }
    public async invoices(
        params: paths['/clients-crm/invoices']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/clients-crm/invoices']['post']['responses']['201']['content']['application/json']
    > {
        return this.post('/clients-crm/invoices', params).then(
            (res) => res.data,
        );
    }

    public async subscription(): Promise<
        paths['/clients-crm/subscription']['get']['responses']['200']['content']['application/json']
    > {
        return this.get('/clients-crm/subscription').then((res) => res.data);
    }

    public async editCard(
        params: paths['/clients-crm/edit-card-details']['post']['requestBody']['content']['application/json'],
    ): Promise<
        paths['/clients-crm/edit-card-details']['post']['responses']['201']['content']['application/json']
    > {
        return this.post('/clients-crm/edit-card-details', params).then(
            (res) => res.data,
        );
    }

    public async changeCard(): Promise<
        paths['/clients-crm/change-card']['post']['responses']['201']['content']['application/json']
    > {
        return this.post('/clients-crm/change-card').then((res) => res.data);
    }
}
