import { SellersBackend } from '@/api/sellers/sellers';
import { components } from '@/api/models/Epinium';
export type AddVendorResp = {
    sellerErrors: {
        uknownError: boolean;
        integrationAlreadyExists: boolean;
        revinculationNotSameSeller: boolean;
    };
    validateIntegration: boolean;
    sellerIntegrated: boolean;
    hasCredentialsToCheck: boolean;
    firstCredential: boolean;
    hasCredentialsAccess: boolean;
    createdSeller?: components['schemas']['SellerDTO'];
};
export default function addVendor(params: {
    name: string;
    country: components['schemas']['CountryDTO'];
    sellerID?: string;
}) {
    return new Promise<AddVendorResp>((resolve) => {
        const vendorResp: AddVendorResp = {
            sellerErrors: {
                uknownError: false,
                integrationAlreadyExists: false,
                revinculationNotSameSeller: false,
            },
            validateIntegration: false,
            sellerIntegrated: false,
            hasCredentialsToCheck: false,
            firstCredential: false,
            hasCredentialsAccess: false,
        };

        try {
            if (!params.country.region || !params.country.spRegion) {
                return vendorResp;
            }
            new SellersBackend()
                .prepareVendorsSpAuth({
                    vendorName: params.name,
                    region: params.country.region,
                    spRegion: params.country.spRegion,
                    vendorID: params.sellerID,
                    countryID: params.country._id as any,
                })
                .then((authRequest) => {
                    const connection: any = window.open(
                        authRequest.url,
                        'Authentication with Amazon',
                        'width=1000, height=650,status=0, toolbar=0, location=no',
                    );
                    const timer = setInterval(async () => {
                        if (connection.closed) {
                            clearInterval(timer);
                            // actualizaremos el componente para que muestre que está cargando
                            const addSellerReponse = await new SellersBackend()
                                .integrateSpVendor({
                                    state: authRequest.state,
                                    renewCredentials: !!params.sellerID,
                                })
                                .catch(() => {
                                    vendorResp.sellerErrors.uknownError = true;
                                });
                            if (addSellerReponse) {
                                vendorResp.hasCredentialsAccess =
                                    addSellerReponse.hasCredentialsAccess;
                                vendorResp.validateIntegration =
                                    addSellerReponse.validateIntegration;
                                if (vendorResp.validateIntegration) {
                                    vendorResp.sellerIntegrated =
                                        addSellerReponse.sellerIntegrated;
                                    vendorResp.hasCredentialsToCheck =
                                        addSellerReponse.hasCredentialsToCheck;
                                    vendorResp.firstCredential =
                                        addSellerReponse.firstIntegration;
                                    vendorResp.createdSeller =
                                        addSellerReponse.createdSeller;
                                } else {
                                    // si es una revinculacion miramos si el revinculationNotSameSeller es true
                                    vendorResp.sellerErrors.revinculationNotSameSeller =
                                        !!addSellerReponse.revinculationNotSameSeller;
                                    vendorResp.sellerErrors.integrationAlreadyExists =
                                        addSellerReponse.alreadyExists;
                                    // si entramos aqui y ningun error es true, ponemos el uknown a true
                                    vendorResp.sellerErrors.uknownError =
                                        !Object.values(vendorResp).some(
                                            (s) => s,
                                        );
                                }
                            } else {
                                vendorResp.sellerErrors.uknownError = true;
                            }
                            resolve(vendorResp);
                        }
                    }, 1000);
                });
        } catch (err) {
            vendorResp.sellerErrors.uknownError = true;
            resolve(vendorResp);
        }
    });
}
